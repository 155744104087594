export default {
  // eslint-disable-next-line no-process-env
  tinyEditorApiKey: process.env.REACT_APP_TINYMCE_EDITOR_API_KEY,
  // eslint-disable-next-line no-process-env
  env: process.env.REACT_APP_ENVIRONMENT,
  // eslint-disable-next-line no-process-env
  x_provider: process.env.REACT_APP_X_PROVIDER,
  // eslint-disable-next-line no-process-env
  managerId: Number(process.env.MANAGER_ID),
  // eslint-disable-next-line no-process-env
  test_mode_token: process.env.TEST_MODE_TOKEN,
};
