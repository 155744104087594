import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { isEmpty } from 'lodash';
import getTesterSalaryByTask from '../../../utils/getTesterSalaryByTask';
import Img from '../../../widgets/Img';
import imgURL from '../../../utils/imgURL';
import { getPreviewLg, getPreviewSm } from '../../projects/getPreviewFromProject';
import { priorityToColor } from '../../projects/priorities';
import { formatToPrettyDate, formatToPrettyDateOrTime } from '../../../utils/formats';
import { status } from '../../../utils/statusToColor';
import MyComponent from '../../../utils/MyComponent';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';
import { mapCategoryIdToName, defaultCategoryValue } from '../../../requests/categories';
import { SHOW_TASK_DETAILS_BONUSES_MAP, TASK_BONUS_STATUS } from '../../../../const/tasks';
import roles from '../../customers/roles/roles';
import urlPageFreelancerDetails from '../../../urls/urlPageFreelancerDetails';
import { getClassesForDeadline } from '../../../utils/getClassesForDeadline';
import TagsWidget from '../../../components/Tags/TagsWidget';
import isDisabledProjectContourComplexity from '../../projects/helpers/isDisabledProjectContourComplexity';
import taskTypes from '../taskTypes';
import isDisabledProjectColorComplexity from '../../projects/helpers/isDisabledProjectColorComplexity';
import calculateTaskPriceByUserRate from '../helpers/calculateTaskPriceByUserRate';
import { getUser } from '../../../requests/users';
import CategoriesWidget from '../../../components/Categories/CategoriesWidget';



class TaskInfoMainInfo extends MyComponent {
  computed = {
    canEditImgType: (state, { userRoles, task, userId }) => {
      return (
        task.status === status.process
                && task.executor_id === userId
                && checkRole(
                  userRoles,
                  [
                    ...groupsRoles.managers.coloring,
                    ...groupsRoles.admin,
                    ...groupsRoles.editors.coloring,
                  ]
                )
      );
    },
  };

  getPrice = () => {
    const { isTester, task, user, userRoles } = this.props;

    const executor = getUser(task.executor_id);

    if (isTester) {
      if (task.finished_at && task.price > 0) {
        return task.price;
      }

      return getTesterSalaryByTask(
        task.time.reduce((acc, item) => {
          return acc + item.minutes;
        }, 0)
      );
    }

    if (user.id) {
      if ((task.status === status.waiting) && !user.is_employee) {
        return calculateTaskPriceByUserRate(task, user, task.project.options);
      }
      if (checkRole(userRoles, [ ...groupsRoles.admin, ...groupsRoles.managers.all, ...groupsRoles.editors.all ])
        && executor
        && !executor.is_employee) {
        return calculateTaskPriceByUserRate(task, executor, task.project.options);
      }
    }
    return user.id ? task.price : '';
  };

  getDeadlineLabel = (task, lang) => {
    if (task.type === taskTypes.editor_checking_artist.type || task.type === taskTypes.artist_choosing.type) {
      return lang['TASK.ARTIST_DEADLINE'];
    }
    if (task.type === taskTypes.editor_checking_designer.type || task.type === taskTypes.designer_choosing.type) {
      return lang['TASK.COLORIST_DEADLINE'];
    }
    return lang['TASK.DEADLINE'];
  };

  showTaskDeadline = (task) => {
    return (task.type === taskTypes.editor_checking_artist.type
      || task.type === taskTypes.editor_checking_designer.type
      || task.type === taskTypes.device_testing.type
      || task.type === taskTypes.artist_drawing.type
      || task.type === taskTypes.designer_coloring.type
      || task.type === taskTypes.artist_choosing.type
      || task.type === taskTypes.designer_choosing.type);
  };

  render () {
    const {
      lang,
      task,
      groupName,
      isChoseExecutor,
      setModalEditType,
      setModalEditField,
      userRoles,
      uniqTags,
      canSetTags,
      setModalTags,
      projectCategories,
      canSetCategories,
      setModalCategories,
      getTaskDeadline,
    } = this.props;

    const {
      canEditImgType,
    } = this.computed;

    const showBonusWidget = !isEmpty(task.bonus) && SHOW_TASK_DETAILS_BONUSES_MAP.includes(task.type) && task.bonus.status !== TASK_BONUS_STATUS.CANCELED;

    const canSeeComplexityLevels = checkRole(userRoles, [ ...groupsRoles.admin, ...groupsRoles.managers.all, ...groupsRoles.editors.all ]);

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__top">
              <div className="kt-widget__media">
                <Img
                  src={imgURL(getPreviewSm(task?.preview || task?.project?.preview))}
                  current={{
                    id: task.id,
                    link: getPreviewLg(task?.preview || task?.project?.preview),
                  }}
                  canFull
                />
              </div>
              <div className="kt-widget__content">
                <div className="kt-widget__info row">
                  <div className='col-xl-5'>
                    <div
                      className="kt-widget__desc"
                      style={{ fontFamily: '\'Open Sans\', \'Helvetica Neue\', Helvetica, Arial, sans-serif' }}
                    >
                      <div className="kt-widget__head">
                        <a className="kt-widget__title no-hover kt-font-dark">{(task.project && task.project.title) || `Task #${task.project_id}`}</a>
                      </div>
                      {task.executor && (
                        <div className="kt-widget__subhead">
                          <Link
                            className='font-weight-bold kt-font-dark'
                            to={urlPageFreelancerDetails({ freelancerId: task.executor.id })}
                          >
                            <i className="flaticon-user" />
                            {task.executor.username}
                          </Link>
                        </div>
                      )}
                      {task.type && (
                        <div className="kt-widget__subhead">
                          <span className='no-hover font-weight-bold kt-font-dark'>
                            {lang[`TASK.TYPES.${task.type}`]}
                          </span>
                        </div>
                      )}
                      {(task.project && task.project.description) && (
                        <div className="kt-widget__subhead kt-font-dark">
                          {(task.project && task.project.description)}
                        </div>
                      )}
                      {(checkRole(this.props.userRoles, [ roles['coloring-designer'].key, roles['coloring-editor'].key, roles.manager.key, roles['content-manager'].key ]) && task?.project?.options && task.project.options.resourceLink) && (
                        <div className="kt-widget__subhead">
                          <a rel="noreferrer" href={task.project.options.resourceLink} target="_blank" className="kt-nav__link" style={{ color: '#2c77f4' }}>
                            {lang['GLOBAL.RESOURCE_LINK']}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-7'>
                    <div className="row">
                      <div className="col-sm-4">
                        {task.project && task.project.priority && (
                          <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                            <span className="kt-widget__date col-7">
                              {lang['GLOBAL.PRIORITY']}
                            </span>
                            <div className="kt-widget__label col-5">
                              <span className={`btn disabled kt-opacity-1 btn-label-${priorityToColor(task.project.priority)} font-weight-bold btn-sm bg-transparent`}>
                                {lang[`GLOBAL.PRIORITY.${task.project.priority}`]}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                          <span className="kt-widget__date col-7">
                            {lang['TASK.BEGIN']}
                          </span>
                          <div className="kt-widget__label col-5">
                            <span className="btn disabled kt-opacity-1 btn-sm btn-bold btn-upper bg-transparent">
                              {formatToPrettyDate(task.started_at) || '---'}
                            </span>
                          </div>
                        </div>
                        {this.showTaskDeadline(task) && (
                          <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                            <span className="kt-widget__date col-7">
                              {this.getDeadlineLabel(task, lang)}
                            </span>
                            <div className="kt-widget__label col-5">
                              <span className={getClassesForDeadline(getTaskDeadline(task))}>
                                {formatToPrettyDateOrTime(getTaskDeadline(task)) || '---'}
                              </span>
                            </div>
                          </div>
                        )}
                        { checkRole(userRoles, [ ...groupsRoles.managers.all, ...groupsRoles.editors.all ]) && <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                          <span className="kt-widget__date col-7">
                            {lang['PROJECT.DEADLINE']}
                          </span>
                          <div className="kt-widget__label col-5">
                            <span className={getClassesForDeadline(task.project.deadline_to)}>
                              {formatToPrettyDateOrTime(task.project.deadline_to) || '---'}
                            </span>
                          </div>
                        </div>}
                        <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                          <span className="kt-widget__date col-7">
                            {lang['PROJECT.DIFFICULTY']}
                          </span>
                          <div className="kt-widget__label col-5">
                            <span className="btn disabled kt-opacity-1 btn-sm btn-bold btn-upper bg-transparent">
                              {task.project.options.difficult || '---'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 offset-2">
                        {task.project && this.props.categories && (
                          <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                            <span className="kt-widget__date col-7">
                              {lang['GLOBAL.CATEGORY']}
                            </span>
                            <div className="kt-widget__label col-5">
                              <span className="btn disabled kt-opacity-1 btn-sm btn-bold btn-upper bg-transparent kt-pl-0">
                                {mapCategoryIdToName(this.props.categories, task.project.options.category_id) || defaultCategoryValue}
                              </span>
                            </div>
                          </div>
                        )}
                        {task.project && task.project.options && task.project.options.type && (
                          <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                            <span className="kt-widget__date col-7">
                              {lang['PROJECT.FIELDS.type']}
                            </span>
                            <div className="kt-widget__label col-5">
                              <Button
                                as={canEditImgType ? 'button' : 'span'}
                                variant={'label-brand'}
                                size={'sm'}
                                className="btn-bold btn-upper"
                                onClick={() => {
                                  if (canEditImgType) {
                                    setModalEditType(true);
                                  }
                                }}
                              >
                                {lang[`PROJECT.FIELDS.type.${task.project.options.type.toLowerCase()}`].toUpperCase()}
                              </Button>
                            </div>
                          </div>
                        )}
                        {canSeeComplexityLevels && task.project.options?.contour_complexity_level && (<div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                          <span className="kt-widget__date col-7">
                            {lang['PROJECT.FIELDS.contour_complexity_level']}
                          </span>
                          <div className="kt-widget__label col-5">
                            {!isDisabledProjectContourComplexity(task.project, taskTypes.artist_drawing.type, this.props.userRoles) ? (
                              <Button
                                variant="label-brand"
                                size="sm"
                                className="btn-bold btn-upper"
                                onClick={() => {
                                  setModalEditField(true, 'showModalEditContourComplexityLevel');
                                }}
                              >
                                {task.project.options?.contour_complexity_level}
                              </Button>
                            ) : (
                              <span className="btn disabled kt-opacity-1 btn-sm btn-bold btn-upper bg-transparent kt-pl-0">
                                {task.project.options?.contour_complexity_level}
                              </span>
                            )}
                          </div>
                        </div>)}
                        {canSeeComplexityLevels && task.project.options?.color_complexity_level && (<div className="kt-widget__item d-flex align-items-baseline kt-mb-10 ">
                          <span className="kt-widget__date col-7">
                            {lang['PROJECT.FIELDS.color_complexity_level']}
                          </span>
                          <div className="kt-widget__label col-5">
                            {!isDisabledProjectColorComplexity(task.project, taskTypes.designer_coloring.type, this.props.userRoles) ? (
                              <Button
                                variant="label-brand"
                                size="sm"
                                className="btn-bold btn-upper"
                                onClick={() => {
                                  setModalEditField(true, 'showModalEditColorComplexityLevel');
                                }}
                              >
                                {task.project.options.color_complexity_level}
                              </Button>
                            ) : (
                              <span className="btn disabled kt-opacity-1 btn-sm btn-bold btn-upper bg-transparent kt-pl-0">
                                {task.project.options.color_complexity_level}
                              </span>
                            )}
                          </div>
                        </div>)}
                        <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                          <span className="kt-widget__date col-7">
                            {lang['PROJECT.TAPS']}
                          </span>
                          <div className="kt-widget__label col-5">
                            <span className="btn disabled kt-opacity-1 btn-sm btn-bold btn-upper bp-transparent kt-pl-0">
                              {task.project.options.taps || '---'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-widget__bottom justify-content-between">

              <div className="kt-widget__item flex-grow-0">
                <div className="kt-widget__icon">
                  <i className="la la-money" />
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__title">{lang['TASK.PRICE']}</span>
                  <span className="kt-widget__value">
                    <span>€</span>
                    {this.getPrice()}
                  </span>
                </div>
              </div>

              {showBonusWidget && (
                <div className="kt-widget__item flex-grow-0">
                  <div className="kt-widget__icon">
                    <i className="la la-money" />
                  </div>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 10, hide: 2000 }}
                    overlay={
                      <Tooltip id={'BONUS_VALUE'}>
                        {task.bonus?.reason || '---'}
                      </Tooltip>
                    }
                  >
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">
                        {lang['TASK.BONUS_VALUE']} &nbsp;
                        <i className="far fa-question-circle kt-pl-3" style={{ color: '#2c77f4' }} />
                      </span>
                      <span className="kt-widget__value">
                        <span>€</span>
                        {task.bonus?.amount || 0}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
              )}

              {this.props.isTester && <div className="kt-widget__item flex-grow-0">
                <div className="kt-widget__details">
                  <span className="kt-widget__title">{lang['TASK.TIME_TRACKING']}</span>
                  <span className="kt-widget__value">
                    {task.time.reduce((acc, item) => {
                      return acc + item.minutes;
                    }, 0)}
                  </span>
                </div>
              </div>}
              <div className="d-flex flex-column">
                <TagsWidget
                  tags={uniqTags}
                  canSetTags={canSetTags}
                  handleAction={() => {
                    setModalTags(true);
                  }}
                />
                <CategoriesWidget
                  handleAction={() => {
                    setModalCategories(true);
                  }}
                  canSetCategories={canSetCategories}
                  categories={projectCategories}
                />
              </div>

              {groupName && isChoseExecutor && (
                <div className="kt-widget__item flex-grow-0">
                  <div className="kt-widget__icon">
                    <i className="flaticon-users-1" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title">{`${lang['PROJECT.GROUP']}: `}</span>
                    <span className="kt-widget__value">
                      {groupName}
                    </span>
                  </div>
                </div>
              )}
              {!groupName && isChoseExecutor && (
                <div className="kt-widget__item flex-grow-0">
                  <div className="kt-widget__icon">
                    <i className="flaticon-users-1 danger" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title danger">{lang['PROJECT.GROUP_WAS_REMOVED']}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const mapStoreToProps = (store) => {
  return {
    userId: store.user.user.id,
    lang: store.language.lang,
    userRoles: store.user.roles,
    categories: store.categories,
    user: store.user.user,
  };
};

export default connect(mapStoreToProps)(TaskInfoMainInfo);
