/**
 * Tag validation
 *
 * @param {string} text
 * @returns {string}
 */
export const tagValidator = (text) => String(text).replace(/[^0-9_a-zA-Z ]/gim, '');

/**
 * Validating an Integer
 *
 * @param {number} number
 * @returns {string}
 */
export const intValidator = (number) => String(number).replace(/[^0-9]/gim, '');

/**
 * Validating an Integer
 *
 * @param {string} number
 * @returns {string}
 */
export const floatValidator = (number) => String(number).replace(/[^0-9.{1}]/gim, '');

/**
 * Validating an Array of Integers
 *
 * @param {number} number
 * @returns {Array}
 */
export const arrayOfIntValidator = (number) => String(number).replace(/[a-zA-Zа-яёА-ЯЁ]+,?/g, '').split(',');

export const versionValidator = (number) => String(number).replace(/[^0-9.]/gim, '');

export const positiveNumberValidator = (string) => string.replace(/^0+|[^0-9]/gim, '');

export const arrayOfIntValidatorWithoutSplit = (number) => String(number).replace(/[a-zA-Zа-яёА-ЯЁ]+,?/g, '');
