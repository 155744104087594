// eslint-disable-next-line max-classes-per-file
import React from 'react';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import MyComponent from '../utils/MyComponent';



const propTypes = {
  item: PropTypes.any,
  variant: PropTypes.any,
  add: PropTypes.any,
};
/**
 * Component for input with drop-down list
 */

class InputSelectorItem extends React.PureComponent {
  render () {
    const { item } = this.props;

    return (this.props.variant === 'cloud' ? (
      <Button
        variant="secondary"
        as={'li'}
        size="sm"
        className="select2-results__option"
        onMouseDown={(event) => {
          this.setState({
            value: '',
          });
          event.preventDefault();
          if (this.props.add) {
            this.props.add(item.value, item);
          }
        }}
      >{item.title}</Button>
    ) : (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li
        className="select2-results__option"
        onMouseDown={(event) => {
          this.setState({
            value: '',
          });
          event.preventDefault();
          if (this.props.add) {
            this.props.add(item.value, item);
          }
        }}
      >{item.title}</li>
    )
    );
  }
}

class InputSelector extends MyComponent {
  _refInput = React.createRef();

  state = {
    focus: false,
    value: '',
    showList: false,
  };

  static propTypesList = PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string,
  }));

  static propTypes = {
    list: InputSelector.propTypesList.isRequired,
    staticTags: InputSelector.propTypesList,
    cur: PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])).isRequired,
    add: PropTypes.func.isRequired,
    remove: PropTypes.func,
    variant: PropTypes.oneOf([ 'cloud' ]),
    item: PropTypes.any,
  };

  static defaultProps = {
    remove: () => null,
    staticTags: [],
    cur: [],
  };

  computed = {
    listItems: ({ value }, { list, cur, staticTags }) => {
      return list
        ? list.filter((item) => {
          return (
            (item.title.toLowerCase().indexOf(value.toLowerCase()) >= 0 || (
              item.slug
                  && item.slug.indexOf(value.toLowerCase().trim().replace(/\s+/g, '-')) >= 0
            ))
              && cur.findIndex((data) => data === item.value) === -1
              && staticTags.findIndex((data) => (data.value === item.value
                || (data.slug ? data.slug === item.slug : false)))
          );
        })
        : [];
    },
    listCur: (state, { list, cur }) => {
      return list.filter((item) => findIndex(cur, (data) => data === item.value) !== -1);
    },
  };

  render () {
    return (
      <span
        role="link"
        tabIndex={0}
        onKeyDown={() => {}}
        className={`select2 select2-container select2-container--default${this.state.focus ? ' select2-container--focus' : ''} ${this.props.isInvalid ? ' is-invalid' : ''}`}
        style={{ width: '100%' }}
        onClick={() => {
          this._refInput.current.focus();
          this.setState({
            showList: true,
          });
        }}
      >
        <span className="selection">
          <span className="select2-selection select2-selection--multiple">
            <ul className="select2-selection__rendered">
              {this.props.staticTags.map((item, index) => {
                return (
                // eslint-disable-next-line react/no-array-index-key
                  <li key={`${index}.${item.value}`} className="select2-selection__choice">
                    {item.title}
                  </li>);
              })}
              {this.computed.listCur.map((item, index) => {
                return (
                // eslint-disable-next-line react/no-array-index-key
                  <li key={`${index}.${item.value}`} className="select2-selection__choice">
                    <span
                      role="link"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      className="select2-selection__choice__remove"
                      onMouseDown={() => {
                        if (this.props.remove) {
                          this.props.remove(item.value, item);
                        }
                      }}
                    >×</span>{item.title}
                  </li>);
              })}
              <li className="select2-search select2-search--inline">
                {this.props.enter && this.state.value && (
                  <i
                    role="link"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    className='flaticon2-plus hover kt-font-sm kt-mr-5'
                    onMouseDown={() => {
                      this.setState({
                        value: '',
                      });
                      this.props.enter(this.state.value);
                    }}
                  />
                )}
                <input
                  ref={this._refInput}
                  className={'select2-search__field'}
                  type="search"
                  autoCapitalize="none"
                  spellCheck="false"
                  role="searchbox"
                  aria-autocomplete="list"
                  placeholder=""
                  value={this.state.value}
                  onChange={(event) => {
                    this.setState({
                      value: event.target.value,
                    });
                  }}
                  onFocus={() => {
                    this.setState({
                      focus: true,
                    });
                  }}
                  onBlur={() => {
                    this.setState({
                      focus: false,
                      showList: false,
                      value: '',
                    });
                  }}
                  onKeyUp={(event) => {
                    if (this.state.value && event.keyCode === 13) {
                      event.preventDefault();
                      if (this.props.enter) {
                        this.setState({
                          value: '',
                        });
                        this.props.enter(this.state.value);
                      }
                    }
                  }}
                  style={{ width: `${(this.state.value.length) + 0.1}em`, outline: 'none' }}
                />
              </li>
            </ul>
          </span>
        </span>
        <span
          className={`select2-dropdown select2-dropdown--below${this.state.showList ? '' : ' select2-dropdown--hidden'}`}
        >
          <span className="select2-results">
            <ul className={`select2-results__options${this.props.variant ? (` select2-results__options--${this.props.variant}`) : ''}`}>
              {this.computed.listItems.map((item) => {
                return (
                  <InputSelectorItem
                    key={`${item.slug}.${item.value}`}
                    variant={this.props.variant}
                    item={item}
                    add={this._add}
                  />
                );
              })}
            </ul>
          </span>
        </span>
      </span>
    );
  }

  _add = (val, item) => {
    if (this.props.add) {
      this.props.add(val, item);
    }
  };
}

InputSelectorItem.propTypes = propTypes;

export default InputSelector;
