// eslint-disable-next-line deprecate/import
import { combineReducers } from 'redux';
import { metronic } from '../../_metronic';
import languageReducer from './lang/language';
import { countriesReducer } from './countries/reducer';
import { configReducer } from './config/reducer';
import { termReducer } from './term/reducer';
import { notificationsReducer } from './notification';
import { pagesReducer } from './pages';
import { tagsReducer } from './tags';
import { manualsReducer } from './manuals/reducer';
import { bonusesReducer } from './bonuses';
import { tasksReducer } from './tasks';
import { categoriesReducer } from './categories/reducer';
import { usersReducer } from './users';
import { groupsReducer } from './groups/reducer';
import { freelancersReducer } from './freelancers/reducer';
import { projectsReducer } from './projects/reducer';
import { billingReducer } from './billing/reducer';
import { policyReducer } from './policy/reducer';
import { userReducer } from './user/reducer';
import { statisticsReducer } from './statistics/reducer';



const appReducer = combineReducers({
  user: userReducer,
  builder: metronic.builder.reducer,
  language: languageReducer,
  countries: countriesReducer,
  config: configReducer,
  pages: pagesReducer,
  term: termReducer,
  notifications: notificationsReducer,
  tags: tagsReducer,
  manuals: manualsReducer,
  bonuses: bonusesReducer,
  tasks: tasksReducer,
  categories: categoriesReducer,
  users: usersReducer,
  groups: groupsReducer,
  freelancers: freelancersReducer,
  projects: projectsReducer,
  billing: billingReducer,
  policy: policyReducer,
  statistics: statisticsReducer,
});

export const rootReducer = (state, action) => appReducer(state, action);
