import React from 'react';
import { connect } from 'react-redux';
import { findLastIndex, uniqBy, orderBy, groupBy } from 'lodash';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import Preload from '../../../widgets/Preload';
import {
  formatDateTimeToString,
  formatStringToDate,
  setDateHours,
} from '../../../utils/formats';
import {
  getListFilesFromProject,
  getPreviewFilesFromProject,
  getPreviewImagesFromProject,
  _getPreviewImagesFromProject,
} from '../getPreviewFromProject';
import typesComments from '../../../utils/typesComments';
import { getGroup } from '../../../requests/groups';
import MyComponent from '../../../utils/MyComponent';
import { status } from '../../../utils/statusToColor';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';
import axiosApiInstance from '../../../requests/utils/api';
import { notification } from '../../../requests/notifications';
import { isEmptyObject } from '../../../utils/checker';
import urlPageProjects from '../../../urls/urlPageProjects';
import { getPriceByColorComplexityLevel, getPriceByContourComplexityLevel } from '../projectPrices';
import userActions from '../../../store/user/actions';
// eslint-disable-next-line import/no-named-as-default
import isGroupExist from '../../../utils/isGroupExist';
import imgURL from '../../../utils/imgURL';
import isDisabledProjectComplexityChange from '../helpers/isDisabledProjectComplexityChange';
import taskTypes from '../../tasks/taskTypes';
import { complexityLevels } from '../../tasks/complexityLevels';
import urlPageProjectsList from '../../../urls/urlPageProjectsList';
import getTaskByTypeFromProject from '../helpers/getTaskByTypeFromProject';
import roles from '../../customers/roles/roles';
import _mainInfo from './_mainInfo';
import _subheader from './_subheader';
import _listTasks from './_listTasks';
import _previewBlocks from './_previewBlocks';
import _modals from './_modals';
import _rightMenu from './_rightMenu';
import _comments from './_comments';



class ProjectInfo extends MyComponent {
  state = {
    project: {
      tasks: [],
    },

    newType: '',
    newContourComplexityLevel: '',
    newColorComplexityLevel: '',
    newArtistPrice: 50,
    newDesignerPrice: 34,
    showModalEditType: false,
    showModalEditContourComplexityLevel: false,
    showModalEditColorComplexityLevel: false,
    showModalEditColoristDeadline: false,
    showModalEditArtistDeadline: false,
    showModalEditProjectDeadline: false,
    showModalEditProjectHCReleaseDate: false,
    showModalEditProjectHCContentType: false,
    newProjectDeadline: '',

    showModalTags: false,
    showModalCategories: false,

    LOAD: true,
    LOAD_TAGS: true,

    modalRemove: false,
    modalRestart: false,
    modalArchive: false,
    modalUnArchive: false,
    restart: {},
    versions: [],
    filesVersion: null,
    textureSize: 0,
  };

  computed = {
    progress: ({ project }) => {
      const indexActiveTask = project.tasks && findLastIndex(project.tasks, (task) => task.status === status.finished) + 1;

      let progress = project.tasks ? (100 / project.tasks.length) * indexActiveTask : 0;

      progress = progress > 100 ? 100 : progress;
      progress = progress < 0 ? 0 : progress;

      return Math.round(progress || 0);
    },
    prices: ({ project }, { config }) => {
      const cType = config && config[project.type];

      const prices = [];

      if (cType && cType.prices) {
        Object.keys(cType.prices).forEach((price) => {
          prices.push(price);
        });
      }

      return prices;
    },
    uniqTags: ({ project }) => {
      return (project && project.tags) ? uniqBy(project.tags, (tag) => tag.id) : [];
    },
    previewFiles: ({ project, filesVersion }) => getPreviewFilesFromProject(project, filesVersion),

    previewImages: ({ project }) => {
      const previewImages = _getPreviewImagesFromProject(project, this.computed.previewFiles);

      return previewImages.length === 1 ? getPreviewImagesFromProject(project) : previewImages;
    },
    referencePreviewImages: ({ project }) => {
      return project.reference_files?.map((file) => {
        return {
          name: file.path.slice(file.path.lastIndexOf('/') + 1),
          link: file.path,
        };
      }) || [];
    },
    listFiles: ({ project }) => getListFilesFromProject(project),
    comments: ({ project }) => {
      const filteredComments = (project && project.comments) ? project.comments.filter((comment) => (comment.status !== typesComments.refuseTask)) : [];

      return this._mergeUsersToComments(filteredComments);
    },
    canComment: ({ project }) => {
      return (project && project.status !== status.finished);
    },
    projectCategories: ({ project }) => {
      return (project && project.categories);
    },
    canSetCategories: ({ project }, { userRoles }) => {
      return (project.status !== status.finished) && checkRole(userRoles, groupsRoles.managers.all);
    },
    sizing: () => {
      let countBlocks = 1;

      if (this.computed.previewImages.length > 0) {
        countBlocks += 1;
      }

      return {
        xl: 12 / countBlocks < 3 ? 3 : 4,
        lg: 12 / countBlocks < 4 ? 6 : 12 / countBlocks,
        md: 12 / countBlocks < 6 ? 6 : 12 / countBlocks,
        sm: 12 / countBlocks < 12 ? 12 : 12 / countBlocks,
      };
    },
    hasBurned: ({ project }) => {
      return project.has_burned || (project.tasks || []).reduce((prev, cur) => {
        return cur.has_burned ? cur.has_burned : prev;
      }, false);
    },
    canSetTags: ({ project }, { user, userRoles }) => {
      return project.manager_id === user.id
        || checkRole(groupsRoles.admin, userRoles)
        || (checkRole(userRoles, [ ...groupsRoles.editors.all ]) && project.status !== status.finished);
    },
    canSeeComplexityLevels: ({}, { userRoles }) => {
      return checkRole(userRoles, [ ...groupsRoles.admin, ...groupsRoles.managers.all, ...groupsRoles.editors.all ]);
    },
    getColoristDeadline: ({ project }) => {
      return getTaskByTypeFromProject(project, taskTypes.designer_coloring.type)?.deadline_to;
    },
    getArtistDeadline: ({ project }) => {
      return getTaskByTypeFromProject(project, taskTypes.artist_drawing.type)?.deadline_to;
    },
    canSetProjectDeadline: ({ project }, { userRoles }) => {
      return (project.status !== status.finished) && checkRole(userRoles, [ ...groupsRoles.managers.all, ...groupsRoles.admin ]);
    },
    canSetArtistDeadline: ({ project }, { userRoles }) => {
      const artistTask = getTaskByTypeFromProject(project, taskTypes.artist_drawing.type);
      const artistChoosingTask = getTaskByTypeFromProject(project, taskTypes.artist_choosing.type);

      return (artistTask && artistTask.status !== status.finished)
        && (
          checkRole(userRoles, [ roles.administrator.key, roles['lead-coloring-editor'].key ])
          || (
            checkRole(userRoles, [ roles['coloring-editor'].key ])
            && artistChoosingTask?.executor_id
            && artistChoosingTask.executor_id === this.props.user.id)
        );
    },
    canSetColoristDeadline: ({ project }, { userRoles }) => {
      const coloristTask = getTaskByTypeFromProject(project, taskTypes.designer_coloring.type);
      const coloristChoosingTask = getTaskByTypeFromProject(project, taskTypes.designer_choosing.type);

      return (coloristChoosingTask && coloristTask.status !== status.finished)
        && (
          checkRole(userRoles, [ roles.administrator.key, roles['lead-coloring-editor'].key ])
          || (
            checkRole(userRoles, [ roles['coloring-editor'].key ])
            && coloristChoosingTask.executor_id
            && coloristChoosingTask.executor_id === this.props.user.id)
        );
    },
  };

  _refComments = React.createRef();

  _mainInfo = _mainInfo.bind(this);

  _subheader = _subheader.bind(this);

  _listTasks = _listTasks.bind(this);

  _previewBlocks = _previewBlocks.bind(this);

  _modals = _modals.bind(this);

  _comments = _comments.bind(this);

  _rightMenu = _rightMenu.bind(this);

  render () {
    return this.state.LOAD ? (
      <Preload />
    ) : (
      <>
        {this._subheader()}
        {this._mainInfo()}
        <div className='row'>
          {this._listTasks()}
          {this._comments()}
          {this._previewBlocks()}
        </div>
        {this._rightMenu()}
        {this._modals()}
      </>);
  }

  componentDidMount () {
    if (!isEmptyObject(this.props.users)) {
      this._getProject()
        .then(() => {
          this.setState({
            newContourComplexityLevel: this.state.project?.options?.contour_complexity_level || '2',
            newArtistPrice: typeof this.state.project.options?.artist_price === 'undefined' ? 50 : this.state.project.options?.artist_price,
            newColorComplexityLevel: this.state.project?.options?.color_complexity_level || '1',
            newDesignerPrice: typeof this.state.project.options?.designer_price === 'undefined' ? 34 : this.state.project.options?.designer_price,
            newHCContentType: this.state.project?.hc_content_type,
          });
        });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.users.length < this.props.users.length) {
      this._getProject()
        .then(() => {
          this.setState({
            newContourComplexityLevel: this.state.project?.options?.contour_complexity_level,
            newColorComplexityLevel: this.state.project?.options?.color_complexity_level,
            newArtistPrice: typeof this.state.project.options?.artist_price === 'undefined' ? 50 : this.state.project.options?.artist_price,
            newDesignerPrice: typeof this.state.project.options?.designer_price === 'undefined' ? 34 : this.state.project.options?.designer_price,
            newHCContentType: this.state.project?.hc_content_type,
          });
        });
    }

    if (prevState.project?.id !== this.state.project?.id) {
      const notificationIds = this.props.user?.notifications?.filter((notification) => {
        return notification.project_id === this.state.project.id && !notification.is_read;
      }).map((notification) => notification.id);

      if (notificationIds?.length > 0) {
        this.props.readNotifications(notificationIds);
      }
    }
  }

  _urlToObject = async (file) => {
    const response = await fetch(imgURL(file.link));
    // here image is url/location of image
    const blob = await response.blob();
    const fl = new File([ blob ], 'image.jpg', { type: blob.type });

    this.setState({
      textureSize: (fl.size / 1024 / 1024).toFixed(2),
    });
  };

  /**
   * Merge users to comments
   *
   * @param {any} comments
   * @returns {*[]|*}
   * @private
   */
  _mergeUsersToComments (comments) {
    if (comments) {
      return comments.map(
        (comment) => {
          comment.user = this.props.users.find((user) => user.id === comment.user_id);

          return comment;
        }
      );
    }
    return [];
  }

  _getProject = async () => {
    this.setState({
      LOAD: true,
    });

    try {
      const response = await axiosApiInstance.get(`projects/${this.props.match.params.id}`, {});
      const msg = response.data;

      if (msg) {
        const versions = orderBy(Object.keys(groupBy(msg.project.files, (file) => file.version_id)).map((v) => Number(v)), [ (v) => v ], [ 'desc' ]);

        const isGetGroup = msg.project.options && msg.project.options.chosen_group && isGroupExist(msg.project.options.chosen_group);

        msg.project.comments.forEach((comment) => {
          comment.created_at = formatStringToDate(comment.created_at);
        });

        this.setState({
          LOAD: isGetGroup,
          modalRestart: false,
          project: msg.project,
          versions,
        }, () => {
          if (isGetGroup) {
            Promise.all([ getGroup(msg.project.options.chosen_group) ]).then((group) => {
              this.setState({
                group: group[0] || [],
                LOAD: false,
              });
            });
          } else {
            this.setState({
              LOAD: false,
            });
          }
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.setState({
        LOAD: false,
      });
    }
  };

  _startProject = () => {
    this.setState({
      LOAD: true,
    });
    const fd = new FormData();

    fd.append('_method', 'PUT');

    axiosApiInstance.post(`projects/${this.props.match.params.id}/start`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_START', 'success');
        this._getProject();
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_PROJECT_START', 'error');
          this.setState({
            LOAD: false,
          });
        }
      });
  };

  _restartProject = () => {
    const { restart, project } = this.state;

    this.setState({
      LOAD: true,
    });
    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append('type', 'restart');
    fd.append('deadline_to', restart.deadline_to);
    restart.tasks.forEach((task, index) => {
      if (task.executor_id !== project.tasks[index].executor_id) {
        fd.append(`tasks[${task.id}]`, task.executor_id || '');
      }
    });

    axiosApiInstance.post(`projects/${this.props.match.params.id}`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_RESTART', 'success');
        this._getProject();
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            LOAD: false,
          });
          this.props.notification('NOTIFICATION.ERROR_PROJECT_RESTART', 'error');
        }
      });
  };

  /**
   * Edit image category project
   *
   * @param {string} newType
   * @returns {void}
   * @private
   */
  _editImageCategoryProject = (newType) => {
    this.setState({
      LOAD: true,
    });
    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append('image_type', newType);

    axiosApiInstance.post(`projects/${this.props.match.params.id}/image-type`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_EDIT_IMAGE_CATEGORY', 'success');
        this._getProject();
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_PROJECT_EDIT_IMAGE_CATEGORY', 'error');
          this.setState({
            LOAD: false,
          });
        }
      });
  };

  _updateComplexityLevel = (complexityLevel, type, price = null) => {
    if (isDisabledProjectComplexityChange(this.state.project, type)) {
      return false;
    }

    this.setState({
      LOAD: true,
    });
    const url = `projects/${this.props.match.params.id}/complexity-level`;
    const higherComplexityLevel = 4;

    axiosApiInstance.put(url, {
      complexity_level: complexityLevel,
      type,
      price,
    })
      .then((response) => {
        let field = 'newDesignerPrice';

        let newPrice = Number(complexityLevel) === higherComplexityLevel ? price : getPriceByColorComplexityLevel(complexityLevel);

        if (type === complexityLevels.contour_complexity_level) {
          field = 'newArtistPrice';
          newPrice = Number(complexityLevel) === higherComplexityLevel ? price : getPriceByContourComplexityLevel(complexityLevel);
        }

        this.setState((prevState) => ({
          project: {
            ...prevState.project,
            options: {
              ...prevState.project?.options,
              [type]: Number(complexityLevel),
            },
            tasks: prevState.project.tasks.map((task) => {
              if (
                (type === complexityLevels.contour_complexity_level && task.type === taskTypes.artist_drawing.type) ||
                (type === complexityLevels.color_complexity_level && task.type === taskTypes.designer_coloring.type)
              ) {
                return {
                  ...task,
                  price: response?.data?.price,
                };
              }

              return task;
            }),
          },
          [field]: newPrice,
        }));
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_COMPLEXITY_LEVEL_CHANGED', 'success');
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_PROJECT_COMPLEXITY_LEVEL_CHANGED', 'error');
        this.setState({
          LOAD: false,
        });
      })
      .finally(() => {
        this.setState({
          LOAD: false,
        });
      });
  };

  _deleteProject = () => {
    this.setState({
      LOAD: true,
    });

    axiosApiInstance.delete(`projects/${this.props.match.params.id}`, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_DELETE', 'success');
        this.props.history.push(urlPageProjects());
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_PROJECT_DELETE', 'error');
          this.setState({
            LOAD: false,
          });
        }
      });
  };

  _archiveProject = () => {
    this.setState({
      LOAD: true,
    });
    const { archiveComment } = this.state;
    const id = parseInt(this.props.match.params.id, 10);
    const data = { projects: [ { id, 'comment': archiveComment } ] };

    axiosApiInstance.post('projects/archive', data)
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_ARCHIVE', 'success');
        this.props.history.push(urlPageProjects());
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_PROJECT_ARCHIVE', 'error');
          this.setState({
            LOAD: false,
          });
        }
      });
  };

  _unArchiveProject = () => {
    this.setState({
      LOAD: true,
    });
    const { unArchiveComment } = this.state;
    const id = parseInt(this.props.match.params.id, 10);
    const data = { id, 'comment': unArchiveComment };

    axiosApiInstance.post('projects/unzip', data)
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_UNARCHIVE', 'success');
        this.props.history.push(urlPageProjectsList({ type: 'archived', debug: 'archived' }));
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_PROJECT_UNARCHIVE', 'error');
          this.setState({
            LOAD: false,
          });
        }
      });
  };

  /**
   * Update comment
   *
   * @param {int} commentId
   * @param {string} comment
   * @param {string} updated_at
   * @returns {void}
   * @private
   */
  _updateComment = (commentId, comment, updated_at) => {
    const data = { comment, updated_at };
    const { project } = this.state;

    axiosApiInstance.put(`comments/${commentId}`, data)
      .then(() => {
        this.setState({
          project: {
            ...project,
            comments: project.comments.map((item) => {
              if (item.id === commentId) {
                return { ...item, comment, updated_at };
              }
              return item;
            }),
          },
        }, () => {
          if (this._refComments.current) {
            this._refComments.current.scrollToBottom();
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_SEND_COMMENT', 'error');
        }
      });
  };

  /**
   * Delete comment
   *
   * @param {int} commentId
   * @returns {void}
   * @private
   */
  _deleteComment = (commentId) => {
    const { project } = this.state;

    axiosApiInstance.delete(`comments/${commentId}`, {})
      .then(() => {
        this.setState({
          project: {
            ...project,
            comments: project.comments.map((item) => {
              if (item.id === commentId) {
                return { ...item, deleted_at: new Date() };
              }
              return item;
            }),
          },
        }, () => {
          if (this._refComments.current) {
            this._refComments.current.scrollToBottom();
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_DELETE_COMMENT', 'error');
        }
      });
  };

  /**
   * Add comment
   *
   * @param {string} comment
   * @returns {void}
   * @private
   */
  _addComment = (comment) => {
    const { project } = this.state;
    const data = {
      project_id: project.id,
      current_role: this.props.currentRole,
      comment,
    };

    axiosApiInstance.post('comments', data)
      .then((res) => {
        this.setState({
          project: {
            ...project,
            comments: [
              ...project.comments,
              {
                id: res.data.id,
                user_id: this.props.user.id,
                project_id: project.id,
                task_id: null,
                comment,
                created_at: new Date(),
                updated_at: null,
                user: {
                  username: this.props.user.username,
                  firstname: this.props.user.firstname,
                  lastname: this.props.user.lastname,
                  avatar: this.props.user.avatar,
                },
              },
            ],
          },
        }, () => {
          if (this._refComments.current) {
            this._refComments.current.scrollToBottom();
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_SEND_COMMENT', 'error');
        }
      });

    this.setState({
      LOAD_BONUS: false,
    });
  };

  _updateProjectDeadline = (deadline) => {
    this.setState({
      LOAD: true,
    });
    const url = `projects/${this.props.match.params.id}/deadline`;

    axiosApiInstance.put(url, {
      deadline: formatDateTimeToString(setDateHours(deadline)),
    })
      .then(() => {
        this.setState((prevState) => ({
          project: {
            ...prevState.project,
            deadline_to: formatDateTimeToString(setDateHours(deadline)),
          },
        }));
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_DEADLINE_CHANGED', 'success');
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_PROJECT_DEADLINE_CHANGED', 'error');
        this.setState({
          LOAD: false,
        });
      })
      .finally(() => {
        this.setState({
          LOAD: false,
        });
      });
  };

  _updateProjectHCReleaseDate = (hcReleaseDate) => {
    this.setState({
      LOAD: true,
    });
    const url = `projects/${this.props.match.params.id}/hc-release-date`;

    axiosApiInstance.put(url, {
      hc_release_date: formatDateTimeToString(setDateHours(hcReleaseDate)),
    })
      .then(() => {
        this.setState((prevState) => ({
          project: {
            ...prevState.project,
            hc_release_date: formatDateTimeToString(setDateHours(hcReleaseDate)),
          },
        }));
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_HC_RELEASE_DATE_CHANGED', 'success');
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_PROJECT_HC_RELEASE_DATE_CHANGED', 'error');
        this.setState({
          LOAD: false,
        });
      })
      .finally(() => {
        this.setState({
          LOAD: false,
        });
      });
  };

  _updateProjectHCContentType = (contentType) => {
    this.setState({
      LOAD: true,
    });
    const url = `projects/${this.props.match.params.id}/hc-content-type`;

    axiosApiInstance.put(url, {
      hc_content_type: contentType,
    })
      .then(() => {
        this.setState((prevState) => ({
          project: {
            ...prevState.project,
            hc_content_type: contentType,
          },
        }));
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_HC_CONTENT_TYPE_CHANGED', 'success');
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_PROJECT_HC_CONTENT_TYPE_CHANGED', 'error');
        this.setState({
          LOAD: false,
        });
      })
      .finally(() => {
        this.setState({
          LOAD: false,
        });
      });
  };

  _updateTaskDeadline = (id, deadline, type) => {
    this.setState({
      LOAD: true,
    });
    const url = `tasks/${id}/deadline`;
    const { project } = this.state;

    axiosApiInstance.put(url, {
      deadline: formatDateTimeToString(setDateHours(deadline)),
    })
      .then(() => {
        this.setState({
          project: {
            ...project,
            tasks: project.tasks.map((item) => {
              if (item.id === id) {
                return { ...item, deadline_to: deadline };
              }
              return item;
            }),
          },
        });
        if (type === taskTypes.artist_drawing.type) {
          this.props.notification('NOTIFICATION.SUCCESS_ARTIST_DEADLINE_CHANGED', 'success');
        } else {
          this.props.notification('NOTIFICATION.SUCCESS_COLORIST_DEADLINE_CHANGED', 'success');
        }
      })
      .catch(() => {
        if (type === taskTypes.artist_drawing.type) {
          this.props.notification('NOTIFICATION.ERROR_ARTIST_DEADLINE_CHANGED', 'error');
        } else {
          this.props.notification('NOTIFICATION.ERROR_COLORIST_DEADLINE_CHANGED', 'error');
        }

        this.setState({
          LOAD: false,
        });
      })
      .finally(() => {
        this.setState({
          LOAD: false,
        });
      });
  };

  _getDefaultDeadline = () => {
    return formatDateTimeToString(new Date(Math.round((Date.now() + 1000 * 60 * 60) / (60 * 60 * 1000)) * (60 * 60 * 1000)));
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userRoles: store.user.roles,
    currentRole: store.user.currentRole,
    user: store.user.user,
    config: store.config,
    categories: store.categories,
    users: store.users,
    groups: store.groups.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
    readNotifications: bindActionCreators(userActions.readNotifications, dispatch),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(ProjectInfo);
