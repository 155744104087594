import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

/**
 * Component for selector
 */
// eslint-disable-next-line react/prefer-stateless-function
class MySelect extends React.Component {
  render () {
    return React.createElement(this.props.as || Select, {
      menuPlacement: 'auto',
      menuPosition: 'relative',
      ...this.props,
      isDisabled: this.props.disabled,
      classNamePrefix: `my-select${this.props.classNamePrefix ? (`--${this.props.classNamePrefix}`) : ''}`,
      filterOption: (value, search) => value.label.toLowerCase().indexOf(search.toLowerCase()) !== -1,
      className: ` form-control my-select ${this.props.classNamePrefix ? (` ${this.props.classNamePrefix}`) : ''} kt-p-0${this.props.isInvalid ? ' is-invalid' : ''} ${this.props.className || ''}`,
    });
  }
}

const itemType = PropTypes.shape({
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  label: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
});

MySelect.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.instanceOf(CreatableSelect),
    PropTypes.instanceOf(Select),
    PropTypes.any,
  ]),
  disabled: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  isInvalid: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.arrayOf(itemType),
  value: PropTypes.oneOfType([ PropTypes.arrayOf(itemType), itemType, PropTypes.string ]),
  onChange: PropTypes.func,
};

export default MySelect;
