//@ts-check
import {
  BELARUS_INTERNATION_COUNTY_CODE,
  RUSSIA_INTERNATION_COUNTY_CODE,
  TRANSIT_SWIFT_MAP,
} from '../../const/system';



/**
 * validation of some string length entry
 *
 * @param {string} field
 * @param {number} length
 * @returns {boolean}
 */
export const checkStringLength = (field, length) => field.trim().length >= 1 && field.length >= length;

/**
 * validation of email entry
 *
 * @param {string} email
 * @returns {boolean}
 */
export const emailCheck = (email) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);


export const isEmptyObject = (object) => {
  // eslint-disable-next-line no-restricted-syntax,fp/no-loops
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      return false;
    }
  }

  return true;
};

export const isSwiftRU = (user) => {
  return user?.swift?.length >= 6 && user.swift.charAt(4).toUpperCase() === 'R' && user.swift.charAt(5).toUpperCase() === 'U';
};

export const isRU = (user) => {
  return Number(user.citizenship?.value) === RUSSIA_INTERNATION_COUNTY_CODE;
};

export const isRUResidence = (user) => {
  return Number(user.residence?.value) === RUSSIA_INTERNATION_COUNTY_CODE;
};

export const isBYCitizenship = (user) => {
  return Number(user.citizenship?.value) === BELARUS_INTERNATION_COUNTY_CODE;
};

export const isBYResidence = (user) => {
  return Number(user.residence?.value) === BELARUS_INTERNATION_COUNTY_CODE;
};

export const isBY = (user) => {
  return isBYCitizenship(user) || Number(user.residence?.value) === BELARUS_INTERNATION_COUNTY_CODE;
};

export const isTransitIban = (user) => {
  return user.swift && TRANSIT_SWIFT_MAP.some((item) => user.swift ? user.swift.startsWith(item) : true);
};

export const isBYPersonalData = (user) => {
  return isBY(user) && !(Boolean(user.has_residence_confirmation?.value) && Boolean(user.has_been_on_territory?.value));
};
