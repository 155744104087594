import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';



const propTypes = {
  lang: PropTypes.object.isRequired,
  sort: PropTypes.object,
  canSort: PropTypes.bool,
  isColoring: PropTypes.bool,
  canSelected: PropTypes.bool,
  isDashboardProjectItems: PropTypes.bool,
  showPreviousExecutor: PropTypes.bool,
  setSort: PropTypes.func,
  canSplit: PropTypes.bool,
  isSelectedStage: PropTypes.bool,
};


class ProjectHeaderTable extends React.Component {
  render () {
    const {
      isColoring,
      canSplit,
      lang,
      canSelected,
      isDashboardProjectItems,
      showPreviousExecutor,
      isSelectedStage,
    } = this.props;

    const renderSortableHeader = (sortType, languageKey) => {
      const { canSort, sort, lang } = this.props;

      if (canSort) {
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className='d-flex text-center justify-content-center align-items-center'
            onClick={() => this._setSort(sortType)}
            style={{ cursor: 'pointer' }}
          >
            <span>{lang[languageKey]}</span>
            {sort[sortType] ? (sort[sortType] === 'asc' ? (
              <i className='flaticon2-up' />
            ) : (
              <i className='flaticon2-down' />
            )) : (
              <i className='flaticon2-sort' />
            )}
          </div>
        );
      }
      return lang[languageKey];
    };

    return (
      <thead>
        <tr>
          {canSelected && (<th />)}
          <th />
          <th>
            {renderSortableHeader('id', 'GLOBAL.ID')}
          </th>
          <th>
            {renderSortableHeader('title', 'TASK.TITLE')}
          </th>
          <th>
            {renderSortableHeader('taps', 'PROJECT.TAPS')}
          </th>
          <th>
            {lang['PROJECT.DIFFICULTY']}
          </th>
          <th>
            {renderSortableHeader('type', 'PROJECT.TYPE')}
          </th>
          {isColoring && (
            <th>
              {renderSortableHeader('image_type', 'PROJECT.IMAGE_TYPE')}
            </th>
          )}
          {canSplit && (
            <th>
              {lang['GLOBAL.CATEGORY']}
            </th>
          )}
          <th>
            {renderSortableHeader('started_at', 'GLOBAL.BEGIN')}
          </th>
          {!isDashboardProjectItems && (
            <th>
              {lang['GLOBAL.PERSONAL_DEADLINE']}
            </th>
          )}
          <th>
            {renderSortableHeader('deadline_to', 'GLOBAL.GLOBAL_DEADLINE')}
          </th>
          <th>
            {renderSortableHeader('priority', 'GLOBAL.PRIORITY')}
          </th>
          <th>
            {renderSortableHeader('status', isSelectedStage ? 'GLOBAL.STATUS' : 'PROJECT.STAGE')}
          </th>

          <th>
            {showPreviousExecutor ? (lang['PROJECT.PREVIOUS_EXECUTOR']) : (lang['PROJECT.CUR_EXECUTOR'])}
          </th>
          <th>{lang['GLOBAL.ACTIONS']}</th>
        </tr>
      </thead>
    );
  }

  /**
   * Set sort
   *
   * @param {string} key
   * @returns {void}
   * @private
   */
  _setSort = (key) => {
    if (this.props.setSort) {
      const sort = {};

      if (!this.props.sort[key]) {
        sort[key] = 'desc';
      } else if (this.props.sort[key] === 'desc') {
        sort[key] = 'asc';
      }

      this.props.setSort(sort);
    }
  };
}

ProjectHeaderTable.defaultProps = {
  sort: {},
  canSort: false,
  isDashboardProjectItems: false,
};

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
  };
};

ProjectHeaderTable.propTypes = propTypes;

export default connect(mapStoreToProps)(ProjectHeaderTable);
