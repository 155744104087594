//@ts-check
import roles from '../pages/customers/roles/roles';
import taskTypes from '../pages/tasks/taskTypes';
import groupsRoles from '../pages/customers/roles/groupsRoles';
import checkRole from './checkRole';


/**
 * Filter comments
 *
 * @param {[string]} userRoles
 * @param {*[]} comments
 * @param {*[]} users
 * @returns {*[]}
 */
export const filterCommentsByRoles = (userRoles, comments, users) => {
  if (checkRole(userRoles, [ roles['coloring-artist'].key ])) {
    return comments.filter((comment) => {
      return !!(comment.task && [
        taskTypes.artists_group_choosing.type,
        taskTypes.artist_choosing.type,
        taskTypes.artist_drawing.type,
        taskTypes.editor_checking_artist.type,
        taskTypes.manager_checking.type,
      ].includes(comment.task.type));
    });
  }

  if (checkRole(userRoles, [ roles['coloring-designer'].key ])) {
    return comments.filter((comment) => {
      return !!(comment.task && [
        taskTypes.designers_group_choosing.type,
        taskTypes.designer_choosing.type,
        taskTypes.designer_coloring.type,
        taskTypes.editor_checking_designer.type,
        taskTypes.device_testing.type,
      ].includes(comment.task.type));
    });
  }

  if (checkRole(userRoles, [ roles['coloring-tester'].key ])) {
    return comments.filter((comment) => {
      return !!(comment.task && [
        taskTypes.designers_group_choosing.type,
        taskTypes.designer_choosing.type,
        taskTypes.designer_coloring.type,
        taskTypes.editor_checking_designer.type,
        taskTypes.device_testing.type,
      ].includes(comment.task.type)) || users.filter((user) => {
        return user.id === comment.user_id && checkRole(user.roles, groupsRoles.managers.all);
      }).length > 0;
    });
  }

  return comments;
};

