import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { uniq } from 'lodash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import imgURL from '../../../utils/imgURL';
import { formatToPrettyDate, formatToPrettyDateOrTime } from '../../../utils/formats';
import statusToColor, { status } from '../../../utils/statusToColor';
import { priorityToColor } from '../../projects/priorities';
import Img from '../../../widgets/Img';
import { getPreviewLg, getPreviewSm } from '../../projects/getPreviewFromProject';
import checkRole from '../../../utils/checkRole';
import taskTypes from '../taskTypes';
import roles from '../../customers/roles/roles';
import { mapCategoryIdToName, defaultCategoryValue } from '../../../requests/categories';
import groupsRoles from '../../customers/roles/groupsRoles';
import { bonusStatusToColor, TASK_BONUS_STATUS } from '../../../../const/tasks';
import urlPageProfile from '../../../urls/urlPageProfile';
import urlPageProjectDetails from '../../../urls/urlPageProjectDetails';
import urlPageTaskDetails from '../../../urls/urlPageTaskDetails';
import urlPageFreelancerDetails from '../../../urls/urlPageFreelancerDetails';
import { getBadgeVariantForDeadline } from '../../../utils/getClassesForDeadline';
import getTesterSalaryByTask from '../../../utils/getTesterSalaryByTask';
import { isSameDomain } from '../../../utils/isSameDomain';
import calculateTaskPriceByUserRate from '../helpers/calculateTaskPriceByUserRate';
import TaskItem from './TaskItem';

/**
 * Task line component for the table list of tasks
 */
class TaskItemTable extends TaskItem {
  constructor (props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount () {
    super.componentDidMount();
    if (this.props.lastEditedTaskId === this.props.task.id && this.props.userRoles.indexOf('lead-coloring-tester') !== -1) {
      this.myRef.current.scrollIntoView();
    }
  }

  showPreviousExecutor () {
    if (this.props.isChecked && this.props.showPrevExecutor) {
      if (!this.state.prevExecutor) {
        return <td />;
      }

      return isSameDomain(this.props.user.provider_id, this.state.prevExecutor.provider_id) ?
        <td>
          <Link
            to={this.props.task.prev_executor === this.props.user.id ? urlPageProfile() : urlPageFreelancerDetails({ freelancerId: this.props.task.prev_executor })}
            className="font-weight-bolder kt-font-md text-dark hover"
          >
            {this.state.prevExecutor.username}
          </Link>
        </td> : <td><span>{this.state.prevExecutor.username}</span></td>;
    }

    return '';
  }

  showCurrentExecutor () {
    if (!this.props.showExecutor) {
      return null;
    }

    let executor = '';

    if (this.props.task.executor_id !== this.props.user.id && this.state.executor) {
      executor = isSameDomain() ? (
        <Link
          to={this.props.task.executor_id === this.props.user.id ? urlPageProfile() : urlPageFreelancerDetails({ freelancerId: this.props.task.executor_id })}
          className="font-weight-bolder kt-font-md text-dark hover"
        >
          {this.state.executor.username}
        </Link>
      ) : <span>{this.state.executor.username}</span>;
    }

    return (
      <td>
        {this.props.task.executor_id === this.props.user.id && (
          <Badge variant="success">
            {this.props.lang['TASK.FOR_YOU']}
          </Badge>
        )}
        {executor}
      </td>
    );
  }

  render () {
    const {
      task,
      lang,
      isColoring,
      isListBonuses,
      showStatus,
      canSelected,
      isContentManager,
      showTaskDeadline,
      showProjectDeadline,
      disableCheckbox,
      listPreview,
      startTask,
      setModalCreateBonus,
      setModalEditBonus,
      setModalAbandonBonus,
      setModalApplyBonus,
      setCurrentTask,
      onSelect,
      selected,
      userRoles,
      toProject,
      isFinishedTask,
      canChangeCategory,
      onClickRow,
      isNotEditable,
      type,
      user,
    } = this.props;

    const { status: _status } = this.computed;
    const canEditTags = (
      (task.type === 'manager_checking' && task.status !== status.finished) && checkRole(userRoles, [ roles['content-manager'].key ])
      || checkRole(userRoles, [ ...groupsRoles.editors.all ])
    );
    const canEditImageType = checkRole(userRoles, [
      roles.administrator.key,
      ...groupsRoles.editors.all,
      ...groupsRoles.managers.all,
    ]);

    const link = toProject ?
      urlPageProjectDetails({ projectId: task.project_id }) :
      urlPageTaskDetails({ taskId: task.id });

    const getPrice = () => {
      if (checkRole(userRoles, [ roles['coloring-tester'].key, roles['lead-coloring-tester'].key, roles.administrator.key ])) {
        if (checkRole(userRoles, roles.administrator.key) && type !== taskTypes.device_testing.type) {
          return `${task.price}€`;
        }
        if (task.status === status.finished && window.location.pathname !== '/bonuses/testers') {
          return `${task.price}€`;
        }

        return `${getTesterSalaryByTask(task.testing_time)}€`;
      }

      if (task.status === status.waiting && !user.is_employee) {
        return `${calculateTaskPriceByUserRate(task, user, task.project_options)}€`;
      }
      return `${task.price}€`;
    };

    const getTestingTimeCell = (task) => {
      return (
        <input
          readOnly
          disabled={isNotEditable}
          className="form-control time-tracker-input"
          value={task.testing_time}
          onClick={() => {
            onClickRow(task);
          }}
        />);
    };

    return (
      <>
        <tr ref={this.myRef}>
          {canSelected && (
            <td>
              <label className="kt-checkbox kt-checkbox__wrapper_unset">
                <input
                  checked={selected}
                  type="checkbox"
                  disabled={!selected && !disableCheckbox}
                  aria-label="..."
                  onChange={() => {
                    if (onSelect) {
                      onSelect(this.props.task);
                    }
                  }}
                />
              </label>
            </td>
          )}
          <td>
            <span className="kt-media kt-media--xxxl">
              <Img
                src={imgURL(getPreviewSm(task.preview || task.links))}
                current={{
                  id: task.id,
                  link: getPreviewLg(task.preview || task.links),
                }}
                canFull
                files={listPreview}
              />
            </span>
          </td>
          <td>
            <Link to={link} className="text-dark hover">
              {task.project_id}
            </Link>
          </td>
          <td>
            <Link to={link} className="text-dark hover">
              {task.title}
            </Link>
          </td>
          {!isContentManager && !isListBonuses && (
            <td className="kt-word-break">
              {lang[`TASK.TYPES.${task.type}`]}
            </td>
          )}
          {isColoring && (
            <>
              {checkRole(userRoles, [
                roles['content-manager'].key,
                roles['coloring-designer'].key,
                roles['coloring-artist'].key,
                roles.administrator.key,
                ...groupsRoles.testers.all,
                ...groupsRoles.editors.all ]) && (
                <>
                  <td>
                    <Badge variant='secondary'>
                      {task.taps || '---'}
                    </Badge>
                  </td>
                  <td>
                    <Badge variant='danger'>
                      {task.difficult || '---'}
                    </Badge>
                  </td>
                </>
              )}
              <td>
                {task.image_type && (
                  <Badge
                    className={canEditImageType ? 'cursor-pointer' : ''} variant="dark" onClick={() => {
                      if (canEditImageType) {
                        this.props.showChangeImageTypeModal(task);
                      }
                    }}
                  >
                    {(lang[`PROJECT.FIELDS.type.${(task.image_type || '').toLowerCase()}`] || '').toUpperCase()}
                  </Badge>
                )}
              </td>
              <td style={{ maxWidth: '200px' }}>
                {task.tags && uniq(task.tags).map((tag) => (
                  <Badge
                    variant="secondary"
                    key={uuidv4()}
                    className={`kt-mr-5 kt-mb-5 ${canEditTags ? 'cursor-pointer' : {}}`}
                    onClick={() => {
                      if (canEditTags) {
                        this.props.setModalTags(true);
                        this.props.setProjectId(task.project_id);
                        this.props.setUpdatedTask(task);
                        this.props.setTags(task.tags);
                      }
                      return false;
                    }}
                  >
                    {tag.name}
                  </Badge>
                ))}
              </td>
            </>
          )}
          {this.props.categories && (
            <td>
              {checkRole(userRoles, [ roles['content-manager'].key, roles.administrator.key ]) ? (
                <Badge
                  variant="info"
                  className="cursor-pointer"
                  onClick={() => {
                    if (canChangeCategory) {
                      this.props.setModalCategories(true);
                      this.props.setProjectId(task.project_id);
                      this.props.setUpdatedTask(task);
                      this.props.setCategoryId(task.category_id);
                    }
                    return false;
                  }}
                >
                  {mapCategoryIdToName(this.props.categories, task.category_id) || defaultCategoryValue}
                </Badge>
              )
                : (
                  <Badge variant="info">
                    {mapCategoryIdToName(this.props.categories, task.category_id) || defaultCategoryValue}
                  </Badge>
                )}

            </td>
          )}
          <td>
            <Badge variant="info">
              {formatToPrettyDate(task.started_at) || '---'}
            </Badge>
          </td>
          {(checkRole(userRoles, [ roles['lead-coloring-editor'].key ]) && isFinishedTask) && (
            <td>
              <Badge variant="warning">
                {formatToPrettyDate(task.checked_at) || '---'}
              </Badge>
            </td>
          )}
          {checkRole(userRoles, [ roles.administrator.key, roles['content-manager'].key, roles['coloring-editor'].key ]) && (
            <>
              <td>
                <Badge variant="info">
                  {formatToPrettyDate(task.finished_at) || '---'}
                </Badge>
              </td>
              <td>
                <Badge variant="info">
                  {formatToPrettyDate(task.paid_at) || '---'}
                </Badge>
              </td>
            </>
          )}
          {showTaskDeadline && !isFinishedTask && (
            <td>
              <Badge variant={getBadgeVariantForDeadline(task.deadline_to)}>
                {formatToPrettyDateOrTime(task.deadline_to) || '---'}
              </Badge>
            </td>
          )}

          {showProjectDeadline && (
            <td>
              <Badge variant={getBadgeVariantForDeadline(task.project_deadline_to)}>
                {formatToPrettyDateOrTime(task.project_deadline_to) || '---'}
              </Badge>
            </td>
          )}
          {!isContentManager && (
            <td>
              {getPrice()}
              {!isListBonuses && task.bonus.status !== TASK_BONUS_STATUS.CANCELED && (
                <>
                  <br />
                  {task.bonus.amount ? `${task.bonus.amount}€` : ''}
                </>
              )}
            </td>
          )}
          {this.props.userRoles.indexOf(roles['lead-coloring-tester'].key) !== -1 &&
            <td className={'hover'}>
              {getTestingTimeCell(task)}
            </td>
          }
          {isListBonuses && (
            <>
              <td>
                <Badge variant={bonusStatusToColor(task.bonus.status)}>
                  {task.bonus.amount ? `${task.bonus.amount}€` : '---'}
                </Badge>
              </td>
              <td>{task.bonus.reason || '---'}</td>
              <td>
                <Badge variant='warning'>
                  {formatToPrettyDateOrTime(task.checked_at) || '---'}
                </Badge>
              </td>
            </>
          )}
          {showStatus && (
            <td>
              <Badge variant={statusToColor(status[_status])}>
                {(lang[`GLOBAL.STATUS.${status[_status]}`] || '') || ''}
              </Badge>
            </td>
          )}
          <td>
            <Badge variant={priorityToColor(task.priority)}>
              {lang[`GLOBAL.PRIORITY.${task.priority}`]}
            </Badge>
          </td>
          {this.showPreviousExecutor()}
          {this.showCurrentExecutor()}
          <td>
            {(startTask && task.status === status.waiting && checkRole(userRoles, [ taskTypes[task.type].role, roles.administrator.key ]) &&
              (task.executor_id ? task.executor_id === this.props.user.id : true)
            ) && (
              <OverlayTrigger
                placement="bottom"
                overlay={(
                  <Tooltip id="taskViewTable">
                    {lang['GLOBAL.START_WORK']}
                  </Tooltip>
                )}
              >
                <Button
                  variant="success"
                  size="sm"
                  className="text-nowrap kt-margin"
                  onClick={() => {
                    this.props.startTask(this.props.task.id);
                  }}
                >
                  <i className="fa fa-forward kt-m-0" />
                </Button>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip id="taskViewTable">
                  {lang['GLOBAL.VIEW']}
                </Tooltip>
              )}
            >
              <Button
                as={Link}
                to={link}
                variant="primary"
                size="sm"
                className="text-nowrap kt-margin"
              >
                <i className="fa fa-eye kt-m-0" />
              </Button>
            </OverlayTrigger>
            {isListBonuses && (checkRole(this.props.userRoles, [ roles['lead-coloring-editor'].key, roles.administrator.key, roles['lead-coloring-tester'].key ])) && (
              <>
                {task.bonus.id ? (
                  <>
                    {(task.bonus.status === TASK_BONUS_STATUS.CANCELED || task.bonus.status === TASK_BONUS_STATUS.WAITING) && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={(
                          <Tooltip id="taskViewTable">
                            {lang['BONUSES.APPLY']}
                          </Tooltip>
                        )}
                      >
                        <Button
                          disabled={isNotEditable}
                          variant={task.bonus.status === TASK_BONUS_STATUS.CANCELED ? 'dark' : 'success'}
                          size="sm"
                          className="text-nowrap kt-margin"
                          onClick={() => {
                            setModalApplyBonus();
                            setCurrentTask({
                              ...task,
                              bonus: {
                                ...task.bonus,
                                status: task.bonus.status === TASK_BONUS_STATUS.CANCELED ? TASK_BONUS_STATUS.WAITING : TASK_BONUS_STATUS.APPLIED,
                              },
                            });
                          }}
                        >
                          <i className="fas fa-check kt-m-0" />
                        </Button>
                      </OverlayTrigger>
                    )}

                    {task.bonus.status === TASK_BONUS_STATUS.WAITING && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={(
                          <Tooltip id="taskViewTable">
                            {lang['BONUSES.EDIT']}
                          </Tooltip>
                        )}
                      >
                        <Button
                          disabled={isNotEditable}
                          variant="secondary"
                          size="sm"
                          className="text-nowrap kt-margin"
                          onClick={() => {
                            setModalEditBonus();
                            setCurrentTask(task);
                          }}
                        >
                          <i className="fas fa-pen kt-m-0" />
                        </Button>
                      </OverlayTrigger>
                    )}

                    {(task.bonus.status === TASK_BONUS_STATUS.APPLIED || task.bonus.status === TASK_BONUS_STATUS.WAITING) && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={(
                          <Tooltip id="taskViewTable">
                            {lang['BONUSES.ABANDON']}
                          </Tooltip>
                        )}
                      >
                        <Button
                          disabled={isNotEditable}
                          variant={task.bonus.status === TASK_BONUS_STATUS.APPLIED ? 'dark' : 'danger'}
                          size="sm"
                          className="text-nowrap kt-margin"
                          onClick={() => {
                            setModalAbandonBonus();
                            setCurrentTask({
                              ...task,
                              bonus: {
                                ...task.bonus,
                                status: task.bonus.status === TASK_BONUS_STATUS.APPLIED ? TASK_BONUS_STATUS.WAITING : TASK_BONUS_STATUS.CANCELED,
                              },
                            });
                          }}
                        >
                          <i className="fas fa-ban kt-m-0" />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </>
                ) : (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={(
                      <Tooltip id="taskViewTable">
                        {lang['BONUSES.CREATE']}
                      </Tooltip>
                    )}
                  >
                    <Button
                      disabled={isNotEditable}
                      variant="warning"
                      size="sm"
                      className="text-nowrap kt-margin"
                      onClick={() => {
                        setModalCreateBonus();
                        setCurrentTask({
                          ...task,
                          bonus: {
                            ...task.bonus,
                            status: TASK_BONUS_STATUS.WAITING,
                          },
                        });
                      }}
                    >
                      <i className="fas fa-plus kt-m-0" />
                    </Button>
                  </OverlayTrigger>
                )}
              </>
            )}
          </td>
        </tr>
      </>
    );
  }
}

TaskItemTable.defaultProps = {
  onClickRow: () => {},
  lastEditedTaskId: '',
  isNotEditable: false,
};

const mapStoreToProps = (store) => ({
  lang: store.language.lang,
  user: store.user.user,
  userRoles: store.user.roles,
  categories: store.categories,
});

export default connect(mapStoreToProps)(TaskItemTable);
