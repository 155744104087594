import React from 'react';
import { connect } from 'react-redux';
import { minBy, orderBy, groupBy } from 'lodash';
import Table from 'react-bootstrap/Table';
import { Chart } from 'chart.js';
import PropTypes from 'prop-types';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import Img from '../../widgets/Img';
import TaskItemTable from '../tasks/list/TaskItemTable';
import TaskHeaderTable from '../tasks/list/TaskHeaderTable';
import { getPreviewLg } from '../projects/getPreviewFromProject';
import { getTasksForExecutor } from '../../requests/tasks';
import numberToMonth from '../../utils/numberToMonth';
import Preload from '../../widgets/Preload';
import { status } from '../../utils/statusToColor';
import {
  formatDate,
  formatStringToDate,
} from '../../utils/formats';
import Tabs from '../../widgets/Tabs';
import imgURL from '../../utils/imgURL';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import { addUserRole, getUser, updateRatesRequest } from '../../requests/users';
import { isEmptyObject } from '../../utils/checker';
import urlPageProfile from '../../urls/urlPageProfile';
import checkRole from '../../utils/checkRole';
import Modal from '../../widgets/Modal';
import { notification } from '../../requests/notifications';
import { parseFromPars, parseToPars } from '../../utils/parseUrlParams';
import axiosApiInstance from '../../requests/utils/api';
import taskTypes from '../tasks/taskTypes';
import groupsUsers, { getGroupUserByRoles } from './roles/groupsUsers';
import roles from './roles/roles';
import ReportToExport from './components/ReportToExport';
import TasksFilter from './list/TasksFilter';
import IndividualUserRates from './components/IndividualUserRates';



const propTypes = {
  lang: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userId: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  userRoles: PropTypes.array.isRequired,
  notification: PropTypes.func.isRequired,
};

const months = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 ];

/**
 * Component for another user profile
 *
 * @returns {*}
 */
class Profile extends React.Component {
  state = {
    customer: {},
    groups: [],
    export: false,
    LOAD: true,
    selectGroup: null,
    listTasks: [],
    finishedListTasks: [],
    listTasksWithoutFilter: [],
    finishedListTasksWithoutFilter: [],
    showAddRoleModal: false,
    showBlockUserModal: false,
    sort: {},
    limit: 100,
    sortFinished: {},
  };

  /**
   *
   * @param {Array} list
   * @returns {*}
   */
  getListPreview = (list) => {
    return list
      .map((task) => ({
        id: task.id,
        link: getPreviewLg(task.preview),
      }))
      .filter((i) => i.link);
  };

  IndividualUserRates = IndividualUserRates.bind(this);

  render () {
    const { customer = {} } = this.state;
    const { lang } = this.props;

    // eslint-disable-next-line consistent-return
    const startWork = customer.tasks ? minBy(customer.tasks, ((task) => {
      const time = formatStringToDate(task.started_at);

      if (time) {
        return time.getTime();
      }
    })) : '';

    this.refChart = React.createRef();

    return this.state.LOAD ? (
      <Preload />
    ) : (
      <>
        <SubHeader
          title={lang['CUSTOMER.VIEW_PROFILE']}
          info={customer.username}
        />
        <div className='kt-portlet'>
          <div className='kt-portlet__body'>
            <div className='kt-widget kt-widget--user-profile-3'>
              <div className='kt-widget__top'>
                <div className='row kt-container kt-container--fluid kt-p-0'>
                  <div className='col-sm-6 col-md-2'>
                    <div className='kt-widget__media d-inline-block'>
                      <Img src={imgURL(customer.avatar)} style={{ maxWidth: '100%' }} />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3 col-xl-3">
                    <div className="kt-widget__subhead kt-pb-0">
                      <div className="kt-form kt-form">
                        <div className="form-group mb-0 d-flex align-items-center">
                          <label className="col-form-label">
                            <p className="kt-widget__username font-weight-bolder no-hover kt-mb-0">
                              {`${customer.username}`}
                            </p>
                          </label>
                          <div className="kt-widget__user d-flex pl-3">
                            <span className={`kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-${customer.inactive ? 'danger' : 'success'}`} >
                              {customer.inactive ? lang['CUSTOMER.LOCKED'] : lang['CUSTOMER.ACTIVE']}
                            </span>
                          </div>
                        </div>
                        {this.customerRoles()}
                        {(checkRole(this.props.userRoles, [ roles.administrator.key ]) && !customer.inactive && customer.is_employee) && (
                          <Button variant="danger" size="sm" onClick={this.toggleBlockUserModal}>{lang['CUSTOMER.BLOCK']}</Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3 col-xl-3">
                    <div className="kt-widget__subhead kt-pb-0">
                      <div className="kt-form kt-form">
                        <div className="form-group mb-0 d-flex align-items-center">
                          <label className="col-form-label">{lang['CUSTOMER.NAME']}:</label>
                          <span className="pl-3">{customer.firstname} {customer?.lastname}</span>
                        </div>
                        {customer?.email && <div className="form-group mb-0 d-flex align-items-center">
                          <label className="col-form-label">
                            {lang['CUSTOMER.EMAIL']}:
                          </label>
                          <span className="pl-3">
                            <a href={`mailto:${customer.email}`}>
                              <i className="flaticon2-new-email pr-2" />
                              {customer.email}
                            </a>
                          </span>
                        </div>}
                        {customer?.telegram && <div className="form-group mb-0 d-flex align-items-center">
                          <label className="col-form-label">
                            {lang['CUSTOMER.TELEGRAM']}:
                          </label>
                          <span className="pl-3">
                            <a href={`tg://resolve?domain=${customer.telegram}`}>{customer.telegram}</a>
                          </span>
                        </div>}
                      </div>
                    </div>
                  </div>
                  {this.IndividualUserRates()}
                  <div className='col-sm-12 col-md-3 col-xl-2'>
                    <div className="kt-widget1 p-0">
                      <div className="kt-widget1__item">
                        <div className="kt-form kt-form">
                          <div className="form-group mb-0 d-flex align-items-center">
                            <label className="col-form-label">{lang['CUSTOMER.START_WORK']}</label>
                            <span className="pl-3 font-weight-bold">
                              {formatDate(startWork?.started_at) || '---'}
                            </span>
                          </div>
                          {checkRole(customer.roles, [ roles['coloring-artist'].key ]) && (<div className="form-group mb-0 d-flex align-items-center">
                            <label className="col-form-label">{lang['CUSTOMER.UPLOAD_LIMIT']}</label>
                            <span className="pl-3 font-weight-bold">
                              {customer?.limit}
                            </span>
                            {this.canUpdateUploadLimit() &&
                              <button
                                className="ml-2 btn btn-primary btn-icon btn-xs"
                                onClick={this.updateUploadLimit}
                              >
                                +5
                              </button>
                            }
                          </div>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Tabs
              tabs={[
                {
                  title: lang['MENU.TASKS'],
                  scrollable: false,
                  body: () => {
                    return (
                      <>
                        <Row>
                          <Col md={6} style={{ display: 'flex', alignItems: 'center' }}>
                            <TasksFilter applyFilter={this.applyFilter} resetFilter={this.resetFilter} />
                          </Col>
                          <Col
                            md={{ offset: 2, span: 4 }}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                          >
                            <ReactToPrint
                              trigger={() => {
                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                // to the root node of the returned component as it will be overwritten.
                                return <Button>{lang['GLOBAL.EXPORT_REPORT']}</Button>;
                              }}
                              content={() => this.componentRef}
                            />
                          </Col>
                        </Row>
                        <div style={{ display: 'none', height: 0 }}>
                          <ReportToExport
                            categories={this.categories}
                            lang={this.props.lang}
                            listPreview={this.getListPreview(this.state.listTasks)}
                            showStatus
                            customer={this.state.customer}
                            /* eslint-disable-next-line no-return-assign */
                            tasks={this.state.listTasks} ref={(el) => (this.componentRef = el)}
                          />
                        </div>
                        {this.buildList(this.state.listTasks)}
                      </>
                    );
                  },
                  beforeRender: () => {
                    const pars = parseToPars({ ...this.state.sort });

                    this.props.history.push(this.props.history.location.pathname + pars);
                  },
                },
                {
                  title: lang['MENU.FINISHED_TASKS'],
                  scrollable: false,
                  body: () => (<>
                    <Row>
                      <Col md={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <TasksFilter applyFilter={this.applyFilterFinishedTasks} resetFilter={this.resetFilter} />
                      </Col>
                      <Col
                        md={{ offset: 2, span: 4 }}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                      >
                        <ReactToPrint
                          trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return <Button>{lang['GLOBAL.EXPORT_REPORT']}</Button>;
                          }}
                          content={() => this.componentRef}
                        />
                      </Col>
                    </Row>
                    <div style={{ display: 'none', height: 0 }}>
                      <ReportToExport
                        categories={this.categories}
                        lang={this.props.lang}
                        listPreview={this.getListPreview(this.state.finishedListTasks)}
                        showStatus
                        customer={this.state.customer}
                        /* eslint-disable-next-line no-return-assign */
                        tasks={this.state.finishedListTasks} ref={(el) => (this.componentRef = el)}
                      />
                    </div>
                    {this.buildList(this.state.finishedListTasks.slice(0, this.state.limit), 'finishedListTasks')}
                    {this.state.limit < this.state.finishedListTasks.length && (
                      <Button
                        variant={'outline-dark'}
                        block
                        onClick={() => {
                          this.setState((prevState) => {
                            return {
                              limit: prevState.limit * 2,
                            };
                          });
                        }}
                      >
                        {lang['GLOBAL.LOAD_MORE']}
                      </Button>
                    )}
                  </>),
                  beforeRender: () => {
                    const pars = parseToPars({ ...this.state.sortFinished });

                    this.props.history.push(this.props.history.location.pathname + pars);
                  },
                },
                {
                  title: lang['CUSTOMER.STATISTIC'],
                  body: () => {
                    const { groups } = this.state;

                    return (
                      <>
                        <div className='mw-100 overflow-auto kt-mb-20'>
                          <div>
                            <canvas
                              ref={this.refChart}
                              style={{
                                display: 'block',
                                width: '100%',
                                height: '100%',
                              }}
                              width="1356"
                              height="400"
                              className="chartjs-render-monitor"
                            />
                          </div>
                          <Table bordered className='vertical-align-middle text-center mt-4' size='sm'>
                            <thead>
                              <tr>
                                <th />
                                {months.map((month) => {
                                  return (
                                    <th key={uuidv4()}>
                                      {`${lang[`MONTH.${numberToMonth(month)}`]}`}
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {this.tableBody()}
                            </tbody>
                          </Table>
                        </div>
                        {this.state.selectGroup !== null && this.buildList(groups[Number(this.state.selectGroup.year)].find((group) => group.dateStr === this.state.selectGroup.date)?.list || [])}
                      </>);
                  },
                  afterRender: () => {
                    this.buildChart();
                  },
                  beforeDestroy: () => {
                    this.destroyChart();
                  },
                },
              ]}
            />
          </div>

          <Modal
            title="Добавить роль"
            show={this.state.showAddRoleModal}
            close={this.toggleAddRoleModal}
            footer={<Button variant="success" onClick={this.addRole}>Добавить</Button>}
          >
            {this.addUserRoleModalBody()}
          </Modal>
          <Modal
            title={lang['CUSTOMER.BLOCK_TITLE']}
            show={this.state.showBlockUserModal}
            close={this.toggleBlockUserModal}
            footer={<Button variant="danger" onClick={this.blockUser}>{lang['GLOBAL.SUBMIT']}</Button>}
          >
            <p>{lang['CUSTOMER.BLOCK_QUESTION']}</p>
          </Modal>
        </div>
      </>
    );
  }

  async componentDidMount () {
    if (!isEmptyObject(this.props.users)) {
      if (Number(this.props.match.params.id) === this.props.userId) {
        this.props.history.replace(urlPageProfile());
      } else {
        await this.getFreelancer();
        this.getListTasks();
        const queryParams = parseFromPars(this.props.history.location.search);

        this.setSort(queryParams, this.state.listTasks, 'listTasks');
      }
    }
  }

  async componentDidUpdate (prevProps) {
    if (prevProps.users.length < this.props.users.length) {
      if (Number(this.props.match.params.id) === this.props.userId) {
        this.props.history.replace(urlPageProfile());
      } else {
        await this.getFreelancer();
        this.getListTasks();
        const queryParams = parseFromPars(this.props.history.location.search);

        this.setSort(queryParams, this.state.listTasks, 'listTasks');
      }
    }
  }

  tableBody = () => {
    const { groups, selectGroup } = this.state;
    const result = [];

    // eslint-disable-next-line no-restricted-syntax,fp/no-loops,guard-for-in
    for (const prop in groups) {
      result.push(
        <tr key={prop}>
          <th>{prop}</th>
          {}
          {months.map((month) => {
            const group = groups[prop].find((gr) => {
              return gr.date.getMonth() === month;
            });

            if (group) {
              return (<td key={uuidv4()}>
                {selectGroup?.year === prop && selectGroup?.date === group.dateStr ? (
                  <Badge
                    className={'cursor-pointer'}
                    variant={'info'}
                    onClick={() => {
                      if (selectGroup?.year === prop && selectGroup?.date === group.dateStr) {
                        this.setState({
                          selectGroup: null,
                        });
                      }
                    }}
                  >
                    {group.list.length}
                  </Badge>
                ) : (
                  <span
                    role="button"
                    tabIndex={0}
                    className='cursor-pointer kt-p-5'
                    onClick={() => {
                      this.setState({
                        selectGroup: {
                          year: prop,
                          date: group.dateStr,
                        },
                      });
                    }}
                    onKeyDown={() => {
                    }}
                  >
                    {group.list.length}
                  </span>
                )}
              </td>);
            }
            return (<td key={uuidv4()}>
              <span
                role="button"
                tabIndex={0}
              >
                0
              </span>
            </td>);
          })}
        </tr>
      );
    }

    return result;
  };

  getListTasks = () => {
    const listTasks = this.state.customer.tasks
      && orderBy(
        this.state.customer.tasks.filter(
          (task) => (task.checked_at === null)
            && (
              (task.type === taskTypes.artist_drawing.type && task.checked_at_artist === null)
              || (task.type === taskTypes.designer_coloring.type && task.checked_at_colorist === null)
              || (task.type !== taskTypes.artist_drawing.type && task.type !== taskTypes.designer_coloring.type)
            )
        ),
        [
          (task) => task.updated_at,
          (task) => task.status,
          (task) => task.deadline_to ? task.deadline_to.getTime() : Infinity,
          (task) => task.priority,
        ],
        [
          'desc',
          'asc',
          'desc',
          'desc',
        ]
      ) || [];

    const finishedListTasks = this.state.customer.tasks
      && orderBy(
        this.state.customer.tasks.filter(
          (task) => ((task.checked_at !== null)
            || (task.type === taskTypes.artist_drawing.type && task.checked_at_artist !== null)
            || (task.type === taskTypes.designer_coloring.type && task.checked_at_colorist !== null))
        ),
        [
          (task) => task.updated_at,
          (task) => task.status,
          (task) => task.deadline_to ? task.deadline_to.getTime() : Infinity,
          (task) => task.priority,
        ],
        [
          'desc',
          'asc',
          'desc',
          'desc',
        ]
      ) || [];

    this.setState({
      listTasks,
      finishedListTasks,
      listTasksWithoutFilter: listTasks,
      finishedListTasksWithoutFilter: finishedListTasks,
    });
  };

  canAddNewRole = () => {
    return this.coloringRoles().length === 1 && checkRole(this.props.userRoles, [ roles.administrator.key ]);
  };

  canUpdateUploadLimit = () => {
    return checkRole(this.props.userRoles, [ roles.administrator.key, roles['coloring-editor'].key, roles['lead-coloring-editor'].key ]);
  };

  addUserRoleModalBody = () => {
    return [ roles['coloring-artist'].key, roles['coloring-designer'].key ].filter((role) => {
      return !this.state.customer.roles.includes(role);
    }).map((role) => {
      return <Badge key={role} variant={'info'}>{role}</Badge>;
    });
  };

  addRole = () => {
    const newRole = [ roles['coloring-artist'].key, roles['coloring-designer'].key ].find((role) => {
      return !this.state.customer.roles.includes(role);
    });

    addUserRole({
      user_id: this.state.customer.id,
      new_role: newRole,
    }).then(() => {
      this.setState({
        customer: {
          ...this.state.customer,
          roles: [ ...this.state.customer.roles, newRole ],
        },
      });
      this.props.notification('NOTIFICATION.ROLE_ADDED', 'success');
    })
      .catch(() => {
        this.props.notification('NOTIFICATION.ROLE_ADDED_ERROR', 'error');
      })
      .finally(() => {
        this.toggleAddRoleModal();
      });
  };

  customerRoles = () => {
    const { customer } = this.state;

    if (customer.roles && customer.roles.length > 0) {
      return customer.roles.filter((role) => (role !== 'user' && role !== 'freelancer'))
        .map((role, index) => {
          return (
            <div key={role} className="form-group mb-0 d-flex no-hover align-items-center">
              <label className="col-form-label"><span className='no-hover'>
                <i className='flaticon2-group pr-2' />
                {roles[role].title}
              </span></label>
              {this.canAddNewRole() && index === 0 &&
                <button
                  className="ml-2 btn btn-clean btn-sm btn-icon btn-icon-md"
                  onClick={this.toggleAddRoleModal}
                >
                  <i className="flaticon2-plus" />
                </button>
              }
            </div>);
        });
    }

    return null;
  };

  coloringRoles = () => {
    return this.state.customer.roles.filter((role) => [ roles['coloring-artist'].key, roles['coloring-designer'].key ].includes(role));
  };

  toggleAddRoleModal = () => {
    this.setState({
      showAddRoleModal: !this.state.showAddRoleModal,
    });
  };

  toggleBlockUserModal = () => {
    this.setState({
      showBlockUserModal: !this.state.showBlockUserModal,
    });
  };

  updateUploadLimit = async () => {
    try {
      this.setState({
        LOAD: true,
      });
      const stepOfUploadLimit = 5;

      await axiosApiInstance.post(`/users/${this.state.customer.id}/limits`, {
        'delta': stepOfUploadLimit,
      });
      this.setState((prevState) => {
        return {
          ...prevState,
          customer: {
            ...prevState.customer,
            limit: prevState.customer.limit + stepOfUploadLimit,
          },
        };
      });
      this.props.notification('NOTIFICATION.SUCCESS_UPDATE_UPLOADS_LIMIT', 'success');
    } catch {
      this.props.notification('NOTIFICATION.ERROR_UPDATE_UPLOADS_LIMIT', 'error');
    } finally {
      this.setState({
        LOAD: false,
      });
    }
  };

  blockUser = async () => {
    try {
      this.setState({
        LOAD: true,
      });
      await axiosApiInstance.put(`/users/${this.state.customer.uuid}/status`, {
        'is_active': false,
      });
      this.toggleBlockUserModal();
      this.setState((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          inactive: true,
        },
      }));
      this.props.notification('NOTIFICATION.SUCCESS_CUSTOMER_BLOCK', 'success');
    } catch {
      this.props.notification('NOTIFICATION.ERROR_CUSTOMER_BLOCK', 'error');
    } finally {
      this.setState({
        LOAD: false,
      });
    }
  };

  /**
   * @param {string} query
   * @returns {void}
   */
  applyFilter = (query) => {
    const projectIds = query.split(',').map((id) => Number(id.trim()));
    const tasks = this.state.listTasksWithoutFilter.filter((task) => {
      return projectIds.indexOf(task.project_id) !== -1;
    });

    this.setState({
      listTasks: tasks,
    });
  };

  applyFilterFinishedTasks = (query) => {
    const projectIds = query.split(',').map((id) => Number(id.trim()));

    const tasks = this.state.finishedListTasksWithoutFilter.filter((task) => {
      return projectIds.indexOf(task.project_id) !== -1;
    });

    this.setState({
      finishedListTasks: tasks,
    });
  };

  resetFilter = () => {
    this.getListTasks();
  };

  /**
   * @param {object} sort
   * @param {Array} list
   * @param {string} field
   * @returns {void}
   */
  setSort = (sort, list, field) => {
    this.setState({ [field === 'listTasks' ? 'sort' : 'sortFinished']: sort }, () => {
      // eslint-disable-next-line fp/no-delete
      Object.keys(sort).forEach((k) => sort[k] === '' && delete sort[k]);
      const keys = Object.keys(sort);

      if (keys.length) {
        const orderedList = orderBy(
          list,
          [ ...keys.map((key) => (task) => task[key]) ],
          [ ...keys.map((key) => sort[key]) ]
        );

        this.setState({ [field]: orderedList });
      } else {
        this.getListTasks();
      }

      const pars = parseToPars({ ...sort });

      this.props.history.push(this.props.history.location.pathname + pars);
    });
  };

  /**
   *
   * @param {Array} list
   * @param {string} field
   * @returns {JSX.Element}
   */
  buildList = (list, field = 'listTasks') => {
    const { customer, sort, sortFinished } = this.state;

    const isColoring = getGroupUserByRoles((customer.roles || []).map((item) => item.slug)).findIndex((group) => group === groupsUsers.coloring) !== -1;
    const listPreview = this.getListPreview(list);

    return (
      <div className='mw-100 mt-4 overflow-auto table-wrapper'>
        <Table
          striped
          className='vertical-align-middle text-center kt-font-md'
          size="sm"
        >
          <TaskHeaderTable
            isColoring={isColoring}
            showExecutor={false}
            showStatus
            canSort
            sort={field === 'listTasks' ? sort : sortFinished}
            setSort={(sort) => this.setSort(sort, list, field)}
          />
          <tbody>
            {list.map((task) => {
              return (
                <TaskItemTable
                  key={uuidv4()}
                  task={task}
                  users={[ customer ]}
                  listPreview={listPreview}
                  isColoring={isColoring}
                  showExecutor={false}
                  showStatus
                  toProject
                />
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  buildChart = () => {
    const {
      lang,
    } = this.props;
    const { groups } = this.state;

    this.destroyChart();
    const datasets = [];
    const getRandomColor = () => {
      return Math.random() * (235 - 35) + 35;
    };

    // eslint-disable-next-line fp/no-loops,no-restricted-syntax,guard-for-in
    for (const prop in groups) {
      datasets.push({
        label: `${lang['REPORTS.COUNT_TASKS']} (${prop})`,
        fill: false,
        borderColor: `rgb(${getRandomColor()}, ${getRandomColor()}, ${getRandomColor()})`,
        tension: 0.1,
        data: months.map((month) => {
          const group = groups[prop].find((gr) => {
            return gr.date.getMonth() === month;
          });

          return group ? group.list.length : 0;
        }),
      });
    }

    if (this.refChart.current) {
      this.chart = new Chart(this.refChart.current, {
        data: {
          labels: months.map((month) => (`${lang[`MONTH.${numberToMonth(month)}`]}`)),
          datasets,
        },
        type: 'line',
      });
    }
  };

  destroyChart = () => {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  };

  getFreelancer = async () => {
    this.setState({
      LOAD: true,
    });

    const userId = Number(this.props.match.params.id);

    await Promise.all([ getTasksForExecutor(userId) ])
      .then((tasks) => {
        const user = getUser(userId);

        user.tasks = tasks[0] ?? [];
        user.tasks.forEach((task) => {
          task.deadline_to = formatStringToDate(task.deadline_to);
        });

        const finishedTasks = user.tasks.filter((task) => task.status === status.finished && task.finished_at);

        const groupsByYear = groupBy(
          finishedTasks,
          (task) => {
            const data = new Date(task.finished_at);

            return data.getFullYear();
          }
        );

        // eslint-disable-next-line fp/no-loops,guard-for-in,no-restricted-syntax
        for (const prop in groupsByYear) {
          let group = groupBy(
            groupsByYear[prop],
            (task) => {
              const data = new Date(task.finished_at);

              return `${data.getFullYear()}-${data.getMonth() + 1}-1`;
            }
          );

          group = orderBy(Object.keys(group).map((date) => {

            return {
              dateStr: date,
              date: formatStringToDate(date),
              list: group[date],
            };
          }), [ 'date', 'asc' ]);

          groupsByYear[prop] = group;
        }

        this.setState({
          customer: user,
          groups: groupsByYear,
          LOAD: false,
        });
      });
  };
}

const mapStoreToProps = (store) => {
  return {
    userRoles: store.user.roles,
    lang: store.language.lang,
    user: store.user.user,
    userId: store.user.user.id,
    users: store.users,
    categories: store.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
    updateRatesRequest: bindActionCreators(updateRatesRequest, dispatch),
  };
};

Profile.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(Profile);
