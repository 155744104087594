import React from 'react';
import PropsTypes from 'prop-types';
import Slider from 'react-slick';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import taskTypes from '../pages/tasks/taskTypes';
import roles from '../pages/customers/roles/roles';
import checkRole from '../utils/checkRole';
import { status } from '../utils/statusToColor';
import Modal from './Modal';



const propTypes = {
  classNameBody: PropsTypes.string,
  title: PropsTypes.string,
  files: PropsTypes.arrayOf(PropsTypes.exact({
    name: PropsTypes.string.isRequired,
    link: PropsTypes.string.isRequired,
    uploadStage: PropsTypes.string,
    id: PropsTypes.number,
  })).isRequired,
  task: PropsTypes.object,
  deleteReferenceFile: PropsTypes.func,
  lang: PropsTypes.object,
  userRoles: PropsTypes.array,
};

/**
 * Component for list images with slider and full size mode
 *
 * @returns {*}
 */
class ImgList extends React.Component {
  state = {
    curFile: 0,
    isFull: false,
    showConfirmModal: false,
    fileId: null,
  };

  refSlider = React.createRef();

  /**
   *
   * @param {string} fileName
   * @returns {string}
   */
  getFileName = (fileName) => {
    return fileName.split('?')[0];
  };

  canDeleteReference = (file) => {
    const task = this.props.task;
    const userRoles = this.props.userRoles;

    if (task && task.status === status.process) {
      if (((task.type === taskTypes.designer_choosing.type) || (task.type === taskTypes.artist_choosing.type)) &&
        checkRole(userRoles, [ roles['remote-coloring-editor'].key, roles['coloring-editor'].key ])
      ) {
        return true;
      }
      if (task.type === taskTypes.artist_drawing.type &&
        checkRole(userRoles, [ roles['coloring-artist'].key ]) &&
        file.uploadStage === taskTypes.artist_drawing.type
      ) {
        return true;
      }
      if (task.type === taskTypes.designer_coloring.type &&
        checkRole(userRoles, [ roles['coloring-designer'].key ]) &&
        file.uploadStage === taskTypes.designer_coloring.type
      ) {
        return true;
      }
    }
    return false;
  };

  render () {
    const { lang } = this.props;

    return (
      <>
        <div className={this.props.classNameBody}>
          <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label d-flex align-items-center justify-content-between w-100">
                <h3 className="kt-portlet__head-title font-weight-bold">
                  {this.props.title}
                </h3>
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    this.curSlide = this.refSlider.current.innerSlider.state.currentSlide;
                    this.setState({
                      isFull: true,
                    }, () => {
                      this.refSlider.current.slickGoTo(this.curSlide);
                    });
                  }}
                >
                  <i className="kt-m-0 flaticon-arrows" />
                </Button>
              </div>
            </div>
            <div className="kt-portlet__body kt-portlet__body--fullheight">
              <div
                className={`img-list ${(!this.props.files || this.props.files.length <= 1) ? 'img-list--solo' : ''}`}
              >
                <div
                  className={`img-list__body${this.state.isFull ? ' img-list__body--full' : ''}`}
                >
                  <div
                    role="link"
                    tabIndex={0}
                    onKeyDown={() => {
                    }}
                    className="img-list__btn img-list__btn__close"
                    onClick={() => {
                      this.curSlide = this.refSlider.current.innerSlider.state.currentSlide;
                      this.setState({
                        isFull: false,
                      }, () => {
                        this.refSlider.current.slickGoTo(this.curSlide);
                      });
                    }}
                  >
                    <i className="la la-close" />
                  </div>
                  {[
                    <Slider
                      key={this.state.isFull}
                      infinite={false}
                      centerMode
                      centerPadding={0}
                      variableWidth={!this.state.isFull}
                      focusOnSelect={false}
                      arrows
                      ref={this.refSlider}
                    >
                      {this.props.files.map((file) => {
                        return (
                          <div key={uuidv4()}>
                            {!this.state.isFull && this.canDeleteReference(file) && (
                              <Button
                                size="sm"
                                className="btn btn-bold btn-danger btn-delete-ref"
                                onClick={() => {
                                  this.setState({ fileId: file.id });
                                  this.setState({ showConfirmModal: true });
                                }}
                              >
                                {lang['GLOBAL.REMOVE']}
                              </Button>
                            )}
                            <a
                              href={this.state.currentImageLink}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => {
                                this.setState({ currentImageLink: file.link });
                              }}
                            >
                              <img src={file.link} alt="img" />
                            </a>
                            <p>
                              <span>{this.getFileName(file.name)}</span>
                            </p>
                          </div>
                        );
                      })}
                    </Slider>,
                  ]}
                </div>
              </div>
            </div>
          </div>
        </div>
        {lang && (
          <Modal
            title={lang['PROJECT.DELETE_REFERENCE_FILE_CONFIRM']}
            show={this.state.showConfirmModal}
            close={() => {
              this.setState({ showConfirmModal: false });
            }}
            footer={<>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.setState({ showConfirmModal: false });
                }}
              >{lang['GLOBAL.CANCEL']}</button>
              <button
                className="btn btn-success"
                onClick={() => {
                  if (this.state.fileId) {
                    this.setState({ showConfirmModal: false });
                    this.props.deleteReferenceFile({
                      fileId: this.state.fileId,
                      taskId: this.props.task.id,
                    });
                  }
                }}
              >{lang['GLOBAL.YES']}</button>
            </>}
          />)}
      </>
    );
  }

  componentDidMount () {
    window.addEventListener('keydown', this.eventKeydown);
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.eventKeydown);
  }

  /**
   * Keydown handler
   *
   * @param {EventListenerOrEventListenerObject} event
   * @returns {void}
   */
  eventKeydown = (event) => {
    if (event.keyCode === 39) {
      this.refSlider.current.slickNext();
    } else if (event.keyCode === 37) {
      this.refSlider.current.slickPrev();
    }
  };
}

ImgList.propTypes = propTypes;

export default ImgList;
