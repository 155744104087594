import { Form } from 'react-bootstrap';
import React from 'react';
import {
  formatDateTimeToString,
  formatStringToDate,
  setDateHours,
} from '../../../utils/formats';
import MyDatePicker from '../../../widgets/DatePicker';

/**
 * Creates an input for a date
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} value
 * @param {Function} onChange
 * @param {any} project
 * @param {boolean} required
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function (field, value = null, onChange = null, project = null, required) {
  if (!project) {
    project = this.state.project;
  }

  const { lang } = this.props;
  const isRequiredField = required || field.isRequired;
  const hasValue = value === null ? !project[field.key] : !value;
  const val = (value === null
    ? (project[field.key]
      ? formatStringToDate(project[field.key])
      : ''
    )
    : (value
      ? formatStringToDate(value)
      : ''
    )
  ) || '';

  return (
    <Form.Group
      as={Form.Row} key={field.key}
      style={{
        marginBottom: '1rem',
        ...(field.isFull ? {} : {
          maxWidth: 1024,
          marginLeft: 'auto',
          marginRight: 'auto',
        }),
      }}
    >
      <div className={field.isColumn ? 'col-12' : 'col-lg-3 col-12'}>
        <Form.Label column>
          {(lang[field.translate] || lang[`PROJECT.FIELDS.${field.key}`] || field.title || '')}
          {required || field.isRequired && (
            <span style={{ color: 'red' }}>{' *'}</span>
          )}
        </Form.Label>
      </div>
      <div className={field.isColumn ? 'col-12 d-flex flex-column' : 'col-lg-9 col-12 d-flex flex-column'}>
        <MyDatePicker
          placeholder={lang['GLOBAL.NO_CHOOSE']}
          isInvalid={this.state.showInvalid && isRequiredField && hasValue}
          minDate={field.min}
          selected={val}
          onChange={(date) => {
            if (date) {
              date = formatDateTimeToString(setDateHours(date));

              if (project.hasOwnProperty('index')) {
                const projects = this.state.projects;

                projects[project.index] = {
                  ...projects[project.index],
                  [field.key]: date,
                };
                this.setState((prevState) => ({
                  ...prevState,
                  projects,
                }));
              } else {
                this.setState((prevState) => ({
                  ...prevState,
                  project: {
                    ...prevState.project,
                    [field.key]: date,
                  },
                }));
              }
            } else {
              onChange('');
            }
          }}
        />
        {field.muted && (
          <Form.Text className="text-muted">
            {field.muted}
          </Form.Text>
        )}
        <Form.Control.Feedback type="invalid">
          {lang['GLOBAL.FIELD_REQUIRED']}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
}
