import actions from './actions';



const initialState = {
  isLoading: false,
  total: 0,
  total_overdue: 0,
  total_coloring: 0,
  total_archived: 0,
  projects: {
    overdue: [],
    coloring: [],
    archived: [],
  },
};

export const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING_PROJECTS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_PROJECTS:
      return {
        ...state,
        ...action.payload,
        projects: {
          ...state.projects,
          [action.payload.type]: action.payload.projects,
        },
      };
    case actions.UPDATE_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.projectType]: state.projects[action.payload.projectType].map((project) => {
            if (project.id === action.payload.project.id) {
              project.options.category_id = Number(action.payload.categoryId);
            }

            return project;
          }),
        },
      };

    default:
      return state;
  }
};
