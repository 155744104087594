import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';



const propTypes = {
  lang: PropTypes.object.isRequired,
  sort: PropTypes.any,
  showExecutor: PropTypes.bool,
  isColoring: PropTypes.bool,
  userRoles: PropTypes.array.isRequired,
  showPrevExecutor: PropTypes.bool,
  showStatus: PropTypes.bool,
  isChecked: PropTypes.bool,
  canSelected: PropTypes.any,
  isContentManager: PropTypes.bool,
  canSort: PropTypes.bool,
  setSort: PropTypes.func,
  isListBonuses: PropTypes.bool,
  isFinishedTask: PropTypes.bool,
  currentPath: PropTypes.string,
  showTaskDeadline: PropTypes.bool,
  showProjectDeadline: PropTypes.bool,
  selectAll: PropTypes.func,
  selectedAll: PropTypes.bool,
};

/**
 * Component for table header with a list of tasks
 *
 * @param {string} key
 * @returns {*}
 */
class TaskHeaderTable extends React.Component {
  state = {
    path: '',
  };

  render () {
    const {
      isColoring,
      showExecutor,
      lang,
      sort,
      canSort,
      showStatus,
      isChecked,
      userRoles,
      showProjectDeadline,
      showTaskDeadline,
      canSelected,
      isContentManager,
      isListBonuses,
      showPrevExecutor,
      isFinishedTask,
    } = this.props;

    return (
      <thead>
        <tr>
          {canSelected && (<th />)}
          <th />
          <th>
            {canSort ? (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className='d-flex text-center justify-content-center align-items-center'
                onClick={() => {
                  this._setSort('id');
                }}
                style={{ cursor: 'pointer' }}
              >
                <span>ID</span>
                {sort.id ? (sort.id === 'asc' ? (
                  <i className='flaticon2-up' />
                ) : (
                  <i className='flaticon2-down' />
                )) : (
                  <i className='flaticon2-sort' />
                )}
              </div>
            ) : (
              'ID'
            )}
          </th>
          <th>
            {canSort ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className='d-flex text-center justify-content-center align-items-center'
                onClick={() => {
                  this._setSort('title');
                }}
                style={{ cursor: 'pointer' }}
              >
                <span>{lang['TASK.TITLE']}</span>
                {sort.title ? (sort.title === 'asc' ? (
                  <i className='flaticon2-up' />
                ) : (
                  <i className='flaticon2-down' />
                )) : (
                  <i className='flaticon2-sort' />
                )}
              </div>
            ) : (
              lang['TASK.TITLE']
            )}
          </th>
          {!isContentManager && !isListBonuses && (
            <th>{lang['TASK.STATUS']}</th>
          )}
          {isColoring && (<>
            <th>
              {canSort ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className='d-flex text-center justify-content-center align-items-center'
                  onClick={() => {
                    this._setSort('taps');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <span>{lang['PROJECT.TAPS']}</span>
                  {sort.taps ? (sort.taps === 'asc' ? (
                    <i className='flaticon2-up' />
                  ) : (
                    <i className='flaticon2-down' />
                  )) : (
                    <i className='flaticon2-sort' />
                  )}
                </div>
              ) : (
                lang['PROJECT.TAPS']
              )}
            </th>
            <th>{lang['PROJECT.DIFFICULTY']}</th>
            <th>
              {canSort ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className='d-flex text-center justify-content-center align-items-center'
                  onClick={() => {
                    this._setSort('image_type');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <span>{lang['TASK.TYPE_IMG']}</span>
                  {sort.image_type ? (sort.image_type === 'asc' ? (
                    <i className='flaticon2-up' />
                  ) : (
                    <i className='flaticon2-down' />
                  )) : (
                    <i className='flaticon2-sort' />
                  )}
                </div>
              ) : (
                lang['TASK.TYPE_IMG']
              )}
            </th>
            <th>{lang['MENU.TAGS']}</th>
          </>)}
          <th>{lang['GLOBAL.CATEGORY']}</th>
          <th>
            {canSort ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className='d-flex text-center justify-content-center align-items-center'
                onClick={() => {
                  this._setSort('started_at');
                }}
                style={{ cursor: 'pointer' }}
              >
                <span>{lang['GLOBAL.BEGIN']}</span>
                {sort.started_at ? (sort.started_at === 'asc' ? (
                  <i className='flaticon2-up' />
                ) : (
                  <i className='flaticon2-down' />
                )) : (
                  <i className='flaticon2-sort' />
                )}
              </div>
            ) : (
              lang['GLOBAL.BEGIN']
            )}
          </th>
          {(checkRole(userRoles, [ roles['lead-coloring-editor'].key ]) && isFinishedTask) && (
            <th>
              {canSort ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className='d-flex text-center justify-content-center align-items-center'
                  onClick={() => {
                    this._setSort('checked_at');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <span>{lang['GLOBAL.FINISHED_AT']}</span>
                  {sort.checked_at ? (sort.checked_at === 'asc' ? (
                    <i className='flaticon2-up' />
                  ) : (
                    <i className='flaticon2-down' />
                  )) : (
                    <i className='flaticon2-sort' />
                  )}
                </div>
              ) : (
                lang['GLOBAL.FINISHED_AT']
              )}
            </th>
          )}
          {checkRole(userRoles, [ roles.administrator.key, roles['content-manager'].key, roles['coloring-editor'].key ]) && (
            <>
              <th>{lang['GLOBAL.FINISHED_AT']}</th>
              <th>{lang['GLOBAL.PAID_AT']}</th>
            </>
          )}
          {showTaskDeadline && !isFinishedTask && (
            <th>
              {canSort ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className='d-flex text-center justify-content-center align-items-center'
                  onClick={() => {
                    this._setSort('deadline_to');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <span>{lang['TASK.DEADLINE']}</span>
                  {sort.deadline_to ? (sort.deadline_to === 'asc' ? (
                    <i className='flaticon2-up' />
                  ) : (
                    <i className='flaticon2-down' />
                  )) : (
                    <i className='flaticon2-sort' />
                  )}
                </div>
              ) : (
                lang['TASK.DEADLINE']
              )}
            </th>
          )}
          {showProjectDeadline && (
            <th>
              {canSort ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className='d-flex text-center justify-content-center align-items-center'
                  onClick={() => {
                    this._setSort('project_deadline_to');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <span>{lang['PROJECT.DEADLINE']}</span>
                  {sort.project_deadline_to ? (sort.project_deadline_to === 'asc' ? (
                    <i className='flaticon2-up' />
                  ) : (
                    <i className='flaticon2-down' />
                  )) : (
                    <i className='flaticon2-sort' />
                  )}
                </div>
              ) : (
                lang['PROJECT.DEADLINE']
              )}
            </th>
          )}
          {!isContentManager && (
            <th>
              {canSort ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className='d-flex text-center justify-content-center align-items-center'
                  onClick={() => {
                    this._setSort('price');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <span>
                    {isListBonuses ? lang['TASK.PRICE'] : lang['TASK.PRICE_AWARD'] }
                  </span>
                  {sort.price ? (sort.price === 'asc' ? (
                    <i className='flaticon2-up' />
                  ) : (
                    <i className='flaticon2-down' />
                  )) : (
                    <i className='flaticon2-sort' />
                  )}
                </div>
              ) : (
                isListBonuses ? lang['TASK.PRICE'] : lang['TASK.PRICE_AWARD']
              )}
            </th>
          )}
          {userRoles.indexOf(roles['lead-coloring-tester'].key) !== -1 &&
          <th>
            {lang['TASK.EXECUTION_TIME']}
          </th>
          }
          {isListBonuses && (
            <>
              <th>{lang['TASK.BONUS']}</th>
              <th>{lang['TASK.BONUS_REASON']}</th>
              <th>{lang['TASK.BONUS_CHECKED']}</th>
            </>
          )}
          {showStatus && (
            <th>{lang['GLOBAL.STATUS']}</th>
          )}
          <th>
            {canSort ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className='d-flex text-center justify-content-center align-items-center'
                onClick={() => {
                  this._setSort('priority');
                }}
                style={{ cursor: 'pointer' }}
              >
                <span>{lang['GLOBAL.PRIORITY']}</span>
                {sort.priority ? (sort.priority === 'asc' ? (
                  <i className='flaticon2-up' />
                ) : (
                  <i className='flaticon2-down' />
                )) : (
                  <i className='flaticon2-sort' />
                )}
              </div>
            ) : (
              lang['GLOBAL.PRIORITY']
            )}
          </th>
          {isChecked && showPrevExecutor && (
            <th>{lang.GLOBAL.PREV_EXECUTOR}</th>
          )}
          {showExecutor && (
            <th>{lang.GLOBAL.EXECUTOR}</th>
          )}
          <th>{lang['GLOBAL.ACTIONS']}</th>
        </tr>
      </thead>
    );
  }

  _setSort = (key) => {
    if (this.props.setSort) {
      const sort = {};

      if (!this.props.sort[key]) {
        sort[key] = 'desc';
      } else if (this.props.sort[key] === 'desc') {
        sort[key] = 'asc';
      } else if (this.props.sort[key] === 'asc') {
        sort[key] = '';
      }

      this.props.setSort({ ...this.props.sort, ...sort });
    }

    this.setState({
      path: this.props.currentPath,
    });
  };
}

TaskHeaderTable.defaultProps = {
  setSort: ((sort) => sort),
  sort: {},
  canSort: false,
};

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userRoles: store.user.roles,
  };
};

TaskHeaderTable.propTypes = propTypes;

export default connect(mapStoreToProps)(TaskHeaderTable);
