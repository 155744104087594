import Projects from '../pages/projects/Projects';
import roles from '../pages/customers/roles/roles';
import Tasks from '../pages/tasks/Tasks';
import { Groups } from '../pages/groups/Groups';
import Bonuses from '../pages/bonuses/Bonuses';
import Freelancers from '../pages/customers/Freelancers';
import Manuals from '../pages/manuals/Manuals';
import Soft from '../pages/soft/Soft';
import Tags from '../pages/tags/Tags';
import Billing from '../pages/billing/Billing';
import { Reports } from '../pages/reports/Reports';
import groupsRoles from '../pages/customers/roles/groupsRoles';
import { globalTypesProject, projectTypes } from '../pages/projects/projectTypes';
import Tutorial from '../pages/tutorial/Tutorial';
import { isScreenLg } from '../utils/size';
import { Categories } from '../pages/categories/Categories';
import urlPageManuals from '../urls/urlPageManuals';
import urlPageGroups from '../urls/urlPageGroups';
import urlPageFreelancers from '../urls/urlPageFreelancers';
import urlPageTutorial from '../urls/urlPageTutorial';
import urlPageSoft from '../urls/urlPageSoft';
import urlPageTags from '../urls/urlPageTags';
import urlPageBilling from '../urls/urlPageBilling';
import urlPageLogout from '../urls/urlPageLogout';
import urlPageBonuses from '../urls/urlPageBonuses';
import urlPageTasks from '../urls/urlPageTasks';
import urlPageCategoriesStatistic from '../urls/urlPageCategoriesStatistic';
import urlPageReports from '../urls/urlPageReports';
import urlPageProjectsList from '../urls/urlPageProjectsList';
import urlPageBonusesSubmenu from '../urls/urlPageBonusesSubmenu';
import urlPageReportsCategory from '../urls/urlPageReportsCategory';
import urlPageTesterBonuses from '../urls/urlPageTesterBonuses';
import { Dashboard } from '../pages/dashboard';
import urlPageDashboard from '../urls/urlPageDashboard';
import urlPageTasksAvailable from '../urls/urlPageTasksAvailable';
import urlPageProjects from '../urls/urlPageProjects';
import ProjectCreate from '../pages/projects/create/ProjectCreate';
import urlPageProjectsCreate from '../urls/urlPageProjectsCreate';
import configActions from './config/actions';



/** submenuForTasks func
 *
 * @param {"type"|"status"} category
 * @param {string} name
 * @returns {*}
 */
const submenuForTasks = (category, name) => [
  {
    page: `/tasks/${category}/${name}/editor_checking_artist`,
    translate: 'TASK.TYPES.editor_checking_artist',
    roles: [ roles['remote-coloring-editor'].key ],
  },
  {
    page: `/tasks/${category}/${name}/artists_group_choosing`,
    translate: 'TASK.TYPES.artists_group_choosing',
    roles: [ roles['lead-coloring-editor'].key ],
  },
  {
    page: `/tasks/${category}/${name}/designers_group_choosing`,
    translate: 'TASK.TYPES.designers_group_choosing',
    roles: [ roles['lead-coloring-editor'].key ],
  },
  {
    page: `/tasks/${category}/${name}/artist_choosing`,
    translate: 'TASK.TYPES.artist_choosing',
    roles: [ roles['coloring-editor'].key ],
  },
  {
    page: `/tasks/${category}/${name}/designer_choosing`,
    translate: 'TASK.TYPES.designer_choosing',
    roles: [ roles['coloring-editor'].key ],
  },
  {
    page: `/tasks/${category}/${name}/editor_checking_designer`,
    translate: 'TASK.TYPES.editor_checking_designer',
    roles: [ roles['remote-coloring-editor'].key ],
  },
];

const submenuForColoringEditor = (category, name) => [
  {
    page: `/tasks/${category}/${name}/available`,
    translate: 'MENU.AVAILABLE',
    roles: [ roles['coloring-editor'].key ],
  },
  {
    page: `/tasks/${category}/${name}/my`,
    translate: 'MENU.MY',
    roles: [ roles['coloring-editor'].key ],
  },
];

/**
 * Default pages state
 *
 * @type {{
 *     page: string,
 *     component: any,
 *     roles?: string[],
 *     antiRoles?: string[],
 *     icon?: string,
 *     aside?: boolean,
 *     translate: string,
 *     typeProject?: string,
 *     submenu?: [],
 *     data: {},
 *     separator?: boolean,
 *     condition?: function(): boolean,
 *     header?: boolean,
 *     lvl?: number
 * }[]}
 */
const initialState = [
  {
    translate: 'MENU.DASHBOARD',
    aside: true,
    icon: 'fa fa-chart-line',
    page: urlPageDashboard(),
    component: Dashboard,
    roles: [ ...groupsRoles.admin, ...groupsRoles.editors.all ],
  },
  {
    translate: 'PROJECT.ADD_PROJECT',
    aside: true,
    icon: 'fa fa-plus',
    page: urlPageProjectsCreate(),
    component: ProjectCreate,
    roles: [ ...groupsRoles.admin, ...groupsRoles.managers.all ],
  },
  {
    page: urlPageProjects(),
    component: Projects,
    roles: [ ...groupsRoles.admin, ...groupsRoles.managers.all, roles['content-manager'].key, ...groupsRoles.editors.all, roles['lead-coloring-tester'].key ],
  },
  {
    translate: 'MENU.COLORING',
    aside: true,
    icon: 'fa fa-palette',
    page: urlPageProjectsList({ type: 'coloring' }),
    component: Projects,
    roles: [ ...groupsRoles.admin, ...groupsRoles.managers.coloring, roles['content-manager'].key, ...groupsRoles.editors.all ],
    typeProject: globalTypesProject.COLORING,
  },
  {
    translate: 'MENU.CATEGORIES',
    aside: true,
    icon: 'far fa-list-alt',
    page: urlPageCategoriesStatistic(),
    component: Categories,
    roles: [ ...groupsRoles.admin, ...groupsRoles.managers.coloring, ...groupsRoles.editors.all ],
  },
  {
    translate: 'MENU.PROJECTS_ARCHIVED',
    aside: true,
    icon: 'flaticon2-folder',
    page: urlPageProjectsList({ type: 'archived', debug: 'archived' }),
    component: Projects,
    roles: [ ...groupsRoles.admin, ...groupsRoles.managers.coloring, ...groupsRoles.editors.coloring ],
  },
  {
    translate: 'MENU.PROJECTS_OVERDUE',
    aside: true,
    icon: 'flaticon2-warning',
    page: urlPageProjectsList({ type: 'overdue?burned=1' }),
    component: Projects,
    roles: [ ...groupsRoles.admin ],
  },
  {
    translate: 'MENU.TASKS',
    aside: true,
    icon: 'flaticon2-document',
    page: urlPageTasks(),
    component: Tasks,
    data: { 'data-tour': 'aside-tasks' },
    roles: [ ...groupsRoles.admin, ...groupsRoles.managers.all, ...groupsRoles.editors.all, ...groupsRoles.executors.all ],
    submenu: [
      {
        page: '/tasks/type/artists_group_choosing/all',
        translate: 'TASK.TYPES.artists_group_choosing',
        data: { 'data-tour': 'aside-tasks-available' },
        roles: [ roles['lead-coloring-editor'].key ],
        submenu: submenuForColoringEditor('type', 'artists_group_choosing'),
      },
      {
        page: '/tasks/type/designers_group_choosing/all',
        translate: 'TASK.TYPES.designers_group_choosing',
        data: { 'data-tour': 'aside-tasks-available' },
        roles: [ roles['lead-coloring-editor'].key ],
        submenu: submenuForColoringEditor('type', 'designers_group_choosing'),
      },
      {
        page: '/tasks/type/artist_choosing/all',
        translate: 'TASK.TYPES.artist_choosing',
        data: { 'data-tour': 'aside-tasks-available' },
        roles: groupsRoles.editors.coloring,
        submenu: submenuForColoringEditor('type', 'artist_choosing'),
      },
      {
        page: '/tasks/type/designer_choosing/all',
        translate: 'TASK.TYPES.designer_choosing',
        data: { 'data-tour': 'aside-tasks-available' },
        roles: groupsRoles.editors.coloring,
        submenu: submenuForColoringEditor('type', 'designer_choosing'),
      },
      {
        page: '/tasks/type/editor_checking_artist/all',
        translate: 'TASK.TYPES.editor_checking_artist',
        data: { 'data-tour': 'aside-tasks-available' },
        roles: groupsRoles.editors.coloring,
        submenu: submenuForColoringEditor('type', 'editor_checking_artist'),
      },
      {
        page: '/tasks/type/editor_checking_designer/all',
        translate: 'TASK.TYPES.editor_checking_designer',
        data: { 'data-tour': 'aside-tasks-available' },
        roles: groupsRoles.editors.coloring,
        submenu: submenuForColoringEditor('type', 'editor_checking_designer'),
      },
      {
        page: urlPageTasksAvailable(),
        translate: 'MENU.AVAILABLE',
        data: { 'data-tour': 'aside-tasks-available' },
        roles: [ ...groupsRoles.admin, ...groupsRoles.managers.all, ...groupsRoles.executors.all ],
        antiRoles: [ ...groupsRoles.editors.coloring ],
        submenu: submenuForTasks('type', 'available'),
      },
      {
        page: '/tasks/list/my',
        translate: 'MENU.MY',
        roles: [ ...groupsRoles.admin, ...groupsRoles.managers.all, ...groupsRoles.executors.all ],
        antiRoles: [ ...groupsRoles.editors.coloring ],
        submenu: submenuForTasks('type', 'my'),
      },
      {
        page: '/tasks/list/review',
        translate: 'MENU.REVIEW',
        roles: [ ...groupsRoles.admin, ...groupsRoles.executors.all ],
        antiRoles: [ ...groupsRoles.managers.all, ...groupsRoles.editors.all, roles['coloring-tester'].key ],
      },
      {
        page: '/tasks/list/reopened',
        translate: 'MENU.REOPENED',
        roles: [ ...groupsRoles.admin, roles['content-manager'].key, roles['coloring-tester'].key ],
      },
      {
        page: '/tasks/list/finished',
        translate: 'MENU.FINISHED',
        roles: [ ...groupsRoles.executors.all, ...groupsRoles.editors.all, roles['content-manager'].key ],
        antiRoles: [ ...groupsRoles.editors.coloring ],
        submenu: submenuForTasks('type', 'finished'),
      },
      {
        page: '/tasks/list/finished',
        translate: 'MENU.MY_FINISHED',
        roles: [ ...groupsRoles.admin, ...groupsRoles.managers.all ],
        antiRoles: [ roles['content-manager'].key ],
        submenu: submenuForTasks('type', 'finished'),
      },
      {
        separator: true,
        page: '/tasks/status/waiting/editor',
        translate: 'MENU.WAITING_EDITOR',
        roles: [ ...groupsRoles.admin, roles['lead-coloring-editor'].key ],
        antiRoles: [ roles['content-manager'].key ],
      },
      {
        page: '/tasks/status/process/editor',
        translate: 'MENU.PROCESS_EDITOR',
        roles: [ ...groupsRoles.admin, roles['lead-coloring-editor'].key ],
        antiRoles: [ roles['content-manager'].key ],
      },
    ],
  },
  {
    translate: 'MENU.GROUPS',
    aside: true,
    icon: 'flaticon-users-1',
    page: urlPageGroups(),
    component: Groups,
    roles: [ ...groupsRoles.admin, ...groupsRoles.editors.all ],
  },
  {
    translate: 'MENU.PAYMENTS',
    aside: true,
    icon: 'fa fa-coins',
    page: urlPageTesterBonuses(),
    component: Bonuses,
    roles: [ roles['lead-coloring-tester'].key ],
  },
  {
    translate: 'MENU.PAYMENTS',
    aside: true,
    icon: 'fa fa-coins',
    page: urlPageBonuses(),
    component: Bonuses,
    roles: [ ...groupsRoles.admin, roles['lead-coloring-editor'].key, roles['coloring-editor'].key ],
    submenu: [
      {
        page: urlPageBonusesSubmenu({ userType: 'artists' }),
        translate: 'BONUSES.ARTISTS',
        roles: [ ...groupsRoles.admin, roles['lead-coloring-editor'].key ],
      },
      {
        page: urlPageBonusesSubmenu({ userType: 'designers' }),
        translate: 'BONUSES.DESIGNERS',
        roles: [ ...groupsRoles.admin, roles['lead-coloring-editor'].key ],
      },
      {
        page: urlPageBonusesSubmenu({ userType: 'testers' }),
        translate: 'BONUSES.TESTERS',
        roles: [ ...groupsRoles.admin ],
      },
    ],
  },
  {
    translate: 'MENU.FREELANCERS',
    aside: true,
    icon: 'flaticon-users-1',
    page: urlPageFreelancers(),
    component: Freelancers,
    roles: [ ...groupsRoles.admin, ...groupsRoles.managers.all, roles['content-manager'].key, ...groupsRoles.editors.coloring ],
  },
  {
    translate: 'MENU.TUTORIAL',
    aside: false,
    header: true,
    icon: 'fa fa-gamepad',
    page: urlPageTutorial(),
    component: Tutorial,
    roles: [ ...groupsRoles.admin, ...groupsRoles.executors.all ],
    antiRoles: [ ...groupsRoles.testers.all, ...groupsRoles.editors.all, ...groupsRoles.managers.all, roles['lead-coloring-editor'].key ],
    condition: () => !isScreenLg(),
  },
  {
    translate: 'MENU.MANUALS',
    aside: true,
    icon: 'fa fa-file',
    page: urlPageManuals(),
    component: Manuals,
    roles: [ ...groupsRoles.admin, ...groupsRoles.managers.all, roles['coloring-editor'].key, roles['lead-coloring-editor'].key, roles['coloring-artist'].key, roles['coloring-designer'].key, roles['coloring-tester'].key ],
    antiRoles: [ roles['content-manager'].key ],
  },
  {
    translate: 'MENU.SOFT',
    aside: true,
    icon: 'flaticon2-menu',
    page: urlPageSoft(),
    component: Soft,
    roles: [ ...groupsRoles.admin, roles['coloring-editor'].key, roles['lead-coloring-editor'].key, roles['coloring-artist'].key, roles['coloring-designer'].key ],
  },
  {
    translate: 'MENU.TAGS',
    aside: true,
    icon: 'fa fa-tags',
    page: urlPageTags(),
    component: Tags,
    roles: [ ...groupsRoles.admin, ...groupsRoles.managers.coloring, roles['content-manager'].key ],
  },
  {
    translate: 'MENU.BILLING',
    aside: true,
    icon: 'fa fa-money-bill-alt',
    page: urlPageBilling(),
    component: Billing,
    roles: [ ...groupsRoles.admin, ...groupsRoles.executors.all ],
    antiRoles: [ roles['coloring-editor'].key, roles.employee.key ],
  },
  {
    translate: 'MENU.REPORTS',
    aside: true,
    icon: 'flaticon2-graph',
    page: urlPageReports(),
    component: Reports,
    roles: [ ...groupsRoles.admin, ...groupsRoles.editors.all, ...groupsRoles.managers.all ],
    submenu: [
      {
        page: urlPageReportsCategory({ category: 'editors' }),
        translate: 'REPORT.TYPE.report_by_editors',
        roles: [ ...groupsRoles.admin ],
      },
      {
        page: urlPageReportsCategory({ category: 'artist' }),
        translate: 'REPORT.TYPE.report_users_analyze',
        roles: [ ...groupsRoles.admin ],
      },
    ],
  },
  {
    aside: true,
    separator: true,
    translate: 'USER_PROFILE.LOGOUT',
    icon: 'flaticon-logout',
    page: urlPageLogout(),
    lvl: 10,
  },
];

/**
 * Reducer for a list of pages
 * Based on the state of this reducer, a side menu and a menu
 * in the header of the site are built. It also builds
 * routing and user access to certain pages.
 *
 * @param {any} state
 * @param {any} action
 * @returns {Array}
 */
export const pagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case configActions.SET_CONFIG: {
      const nState = [ ...state ];

      nState.forEach((item, index) => {
        if (item.typeProject) {
          nState[index] = {
            ...nState[index],
            submenu: [],
          };

          // eslint-disable-next-line no-restricted-syntax,fp/no-loops
          for (const key in action.payload) {
            if (action.payload.hasOwnProperty(key)) {
              const typeProject = projectTypes.find((item) => item.key === key);

              if (typeProject && (
                typeProject.type === item.typeProject
              )) {
                const coloringRoutes = [
                  {
                    page: `${item.page}?page=1&limit=50&project_type=${key}&status=draft`,
                    translate: 'MENU.DRAFT',
                    roles: item.roles,
                    antiRoles: [ ...groupsRoles.editors.all ],
                  },
                  {
                    page: `${item.page}?page=1&limit=50&project_type=${key}&status=waiting`,
                    translate: 'MENU.WAITING',
                    roles: item.roles,
                  },
                  {
                    page: `${item.page}?page=1&limit=50&project_type=${key}&status=process`,
                    translate: 'MENU.PROCESS',
                    roles: item.roles,
                  },
                  {
                    page: `${urlPageProjectsList({ type: 'coloring' })}?page=1&limit=50&project_type=${key}&status=finished`,
                    translate: 'MENU.FINISHED',
                    roles: item.roles,
                  },
                ];

                if (key === 'coloring-any') {
                  coloringRoutes.push({
                    page: `${item.page}?page=1&limit=50&project_type=${key}&status=reopened`,
                    translate: 'MENU.REOPENED',
                    roles: item.roles,
                  },);
                }

                nState[index].submenu.push({
                  page: item.typeProject === globalTypesProject.COLORING ? `${item.page}?page=1&limit=50&project_type=${key}` : `page=1&limit=50&${item.page}project_type=${key}`,
                  title: action.payload[key].name,
                  roles: item.roles,
                  bullet: 'dot',
                  submenu: item.typeProject === globalTypesProject.COLORING ? coloringRoutes : [
                    {
                      page: `${item.page}project_type=${key}&status=waiting&page=1&limit=50`,
                      translate: 'MENU.WAITING',
                      roles: item.roles,
                    },
                    {
                      page: `${item.page}project_type=${key}&status=process&page=1&limit=50`,
                      translate: 'MENU.PROCESS',
                      roles: item.roles,
                    },
                    {
                      page: `${item.page}project_type=${key}&status=finished&page=1&limit=50`,
                      translate: 'MENU.FINISHED',
                      roles: item.roles,
                    },
                  ],
                });
              }
            }
          }
        }
      });

      return nState;
    }

    default:
      return state;
  }
};
