import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import checkRole from '../../utils/checkRole';
import groupsRoles from '../customers/roles/groupsRoles';
import roles from '../customers/roles/roles';
import urlPageProjects from '../../urls/urlPageProjects';
import urlPageProjectsCreate from '../../urls/urlPageProjectsCreate';
import urlPageProjectsList from '../../urls/urlPageProjectsList';
import urlPageError_v4 from '../../urls/urlPageError_v4';
import urlPageProjectDetails from '../../urls/urlPageProjectDetails';
import urlPageProjectEdit from '../../urls/urlPageProjectEdit';
import urlPageProjectsEdit from '../../urls/urlPageProjectsEdit';
import ProjectCreate from './create/ProjectCreate';
import ProjectsEdit from './edit';
import ProjectEdit from './ProjectEdit';
import ProjectFullInfo from './info/ProjectInfo';
import ProjectList from './list';



const propTypes = {
  cRoles: PropTypes.array,
};

/**
 * Component for routing in projects
 */

const Projects = ({ cRoles }) => {
  const canEditProjects = checkRole([ ...groupsRoles.admin, ...groupsRoles.managers.all, roles['lead-coloring-editor'].key ], cRoles);

  return (
    <Switch>
      <Redirect exact from={urlPageProjects()} to={urlPageProjectsList({ type: 'coloring' })} />

      {checkRole([ ...groupsRoles.admin, ...groupsRoles.managers.all ], cRoles) ? (
        <Route exact path={urlPageProjectsCreate()} component={ProjectCreate} />
      ) : (
        <Redirect exact from={urlPageProjectsCreate()} to={urlPageProjectsList({ type: 'coloring' })} />
      )}
      <Route path={[ `${urlPageProjectsList()}` || '/projects/list/' ]} component={ProjectList} />
      {canEditProjects && (
        <Route path={urlPageProjectEdit()} component={ProjectEdit} />
      )}
      {canEditProjects && (
        <Route path={urlPageProjectsEdit()} component={ProjectsEdit} />
      )}
      <Route exact path={urlPageProjectDetails()} component={ProjectFullInfo} />
      <Redirect to={urlPageError_v4()} />
    </Switch>
  );
};

const mapStoreToProps = (store) => ({
  cRoles: store.user.roles,
});

Projects.propTypes = propTypes;

export default connect(mapStoreToProps)(Projects);
