import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { flatten, groupBy } from 'lodash';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import { GeneralSettings, ProjectSettings, ReviewAndSubmit } from '../icons';
import { projectTypes } from '../projectTypes';
import { formatDateTimeToString, setDateHours } from '../../../utils/formats';
import { parseFromPars } from '../../../utils/parseUrlParams';
import Preload from '../../../widgets/Preload';
import Modal from '../../../widgets/Modal';
import { prioritiesMap } from '../priorities';
import axiosApiInstance from '../../../requests/utils/api';
import { notification } from '../../../requests/notifications';
import { ADD_DEFAULT_DEADLINE_DAYS } from '../../../../const/system';
import urlPageProjects from '../../../urls/urlPageProjects';
import FormActions from '../components/FormActions';
import {
  colorComplexityLevels,
  contourComplexityLevels,
  getPriceByColorComplexityLevel,
  getPriceByContourComplexityLevel,
} from '../projectPrices';
import _buildView from './_buildView';
import _checkField from './_checkField';
import _filterAddedFields from './_filterAddedFields';
import _buildField from './_buildField';
import _switchInput from './_switchInput';
import _buildInput from './_buildInput';
import _addField from './_addField';
import _buildInputSelect from './_buildInputSelect';
import _buildTextarea from './_buildTextarea';
import _buildHtmlEditor from './_buildHtmlEditor';
import _buildDate from './_buildDate';
import _buildSelector from './_buildSelector';
import _buildSelectorSearch from './_buildSelectorSearch';
import _buildSwitch from './_buildSwitch';
import _stepBodyGeneralSettings from './_stepBodyGeneralSettings';
import _stepBodyReviewAndSubmit from './_stepBodyReviewAndSubmit';
import _buildProject from './_buildProject';
import _stepBodyListProjects from './_stepBodyListProjects';
import _buildViewProject from './_buildViewProject';
import _checkProject from './_checkProject';



const propTypes = {
  lang: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  config: PropTypes.object,
  notification: PropTypes.func,
};

/**
 * Get default deadline
 *
 * @param {int} addDays
 * @returns {string}
 * @private
 */
const getDefaultDeadline = (addDays) => {
  const date = new Date(Math.round((Date.now() + 1000 * 60 * 60) / (60 * 60 * 1000)) * (60 * 60 * 1000));

  return formatDateTimeToString(setDateHours(new Date(date.setDate(date.getDate() + addDays))));
};


class ProjectCreate extends React.Component {
  state = {
    curStep: 0,
    showInvalid: false,
    invalidProjectTitles: [],
    project: {
      project_type: '',
      difficult: null,
      deadline: getDefaultDeadline(ADD_DEFAULT_DEADLINE_DAYS),
      image_id: null,
      priority: prioritiesMap.medium,
      type: 'sharp',
      prices: {},
      status: 'on',
      visibility: 'off',
      artist_price: getPriceByContourComplexityLevel(Number(contourComplexityLevels[contourComplexityLevels.length - 1])),
      designer_price: getPriceByColorComplexityLevel(Number(colorComplexityLevels[colorComplexityLevels.length - 1])),
      contour_complexity_level: contourComplexityLevels[1],
      color_complexity_level: colorComplexityLevels[0],
      category: '',
    },
    projects: [],
    countProjects: '1',
    modalCreateShutterstock: false,
    sendingData: false,
    LOAD: false,
  };

  constructor (props) {
    super(props);
  }

  componentDidMount () {
    const pars = parseFromPars(this.props.history.location.search);

    const prices = {};

    if (pars.project_type) {
      const { config } = this.props;
      const type = config[pars.project_type];

      if (type && type.steps) {
        Object.values(type.steps).forEach((step, key) => {
          prices[key] = String(step.price);
        });
      }
    }

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      project: {
        ...this.state.project,
        project_type: pars.project_type ? pars.project_type : '',
        prices,
      },
    });
  }

  _checkField = _checkField.bind(this);

  _checkProject = _checkProject.bind(this);

  _steps = [
    {
      icon: GeneralSettings,
      title: 'PROJECT.GENERAL_SETTINGS',
      check: () => {
        const { project, countProjects } = this.state;

        if (project.hasOwnProperty('project_type') && project.project_type === 'coloring-any') {
          return countProjects && Number(countProjects) > 0 && project.project_type && project.source;
        }

        return countProjects && Number(countProjects) > 0 && project.project_type;
      },
      body: _stepBodyGeneralSettings.bind(this),
    },
    {
      check: () => {
        // eslint-disable-next-line fp/no-loops,no-restricted-syntax
        for (const project of this.state.projects) {
          if (!this._checkProject({
            ...this.state.project,
            ...project,
            prices: {
              ...this.state.project.prices,
              ...project.prices,
            },
          })) {
            return false;
          }
        }

        return true;
      },
      icon: ProjectSettings,
      title: 'PROJECT.SETTINGS',
      body: _stepBodyListProjects.bind(this),
      preRender: () => {
        const projects = [ ...this.state.projects ];
        const countProjects = Number(this.state.countProjects);

        if (countProjects > projects.length) {
          // eslint-disable-next-line fp/no-loops
          for (let i = projects.length; i < countProjects; i += 1) {
            projects.push({
              prices: {},
              artist_price: this.state.project.artist_price,
              designer_price: this.state.project.designer_price,
            });
          }
        }

        this.setState({
          projects,
        });
      },
    },
    {
      icon: ReviewAndSubmit,
      title: 'PROJECT.REVIEW_AND_SUBMIT',
      body: _stepBodyReviewAndSubmit.bind(this),
    },
  ];

  _buildProject = _buildProject.bind(this);

  _buildViewProject = _buildViewProject.bind(this);

  _buildView = _buildView.bind(this);

  _filterAddedFields = _filterAddedFields.bind(this);

  _buildField = _buildField.bind(this);

  _switchInput = _switchInput.bind(this);

  _buildInput = _buildInput.bind(this);

  _buildInputSelect = _buildInputSelect.bind(this);

  _buildTextarea = _buildTextarea.bind(this);

  _buildHtmlEditor = _buildHtmlEditor.bind(this);

  _buildDate = _buildDate.bind(this);

  _buildSelector = _buildSelector.bind(this);

  _buildSelectorSearch = _buildSelectorSearch.bind(this);

  _buildSwitch = _buildSwitch.bind(this);

  _addField = _addField.bind(this);

  static _getDefaultDelayStart = () => {
    return formatDateTimeToString(new Date(Math.round((Date.now() + 1000 * 60 * 60) / (60 * 60 * 1000)) * (60 * 60 * 1000)));
  };

  componentDidUpdate (prevProps) {
    if (prevProps.config !== this.props.config) {
      const { config } = this.props;
      const type = config[this.state.project.project_type];

      if (type && type.steps) {
        const prices = {};

        Object.values(type.steps).forEach((step, key) => {
          prices[key] = String(step.price);
        });

        this.setState({
          project: {
            ...this.state.project,
            prices,
          },
        });
      }
    }
  }

  /**
   * Get project type
   *
   * @param {string} projectType
   * @param {string} projectSource
   * @returns {string|*}
   * @private
   */
  _getProjectType = (projectType, projectSource) => {
    if (projectSource === 'shutterstock') {
      return `coloring-${projectSource}`;
    }
    if (projectSource === 'any') {
      return 'coloring-other';
    }

    return projectType;
  };

  _postProject = () => {
    this.setState({
      LOAD: true,
    });

    const { project } = this.state;
    const projectType = this._getProjectType(project.project_type, project.source);

    const fd = new FormData();

    fd.append('type', projectType);

    this.state.projects.slice(0, Number(this.state.countProjects)).forEach((project, index) => {
      project = {
        ...this.state.project,
        ...project,
        prices: {
          ...this.state.project.prices,
          ...project.prices,
        },
      };

      const type = projectTypes.find((item) => item.key === project.project_type);

      if (type.generalSettings) {
        type.generalSettings.forEach((field) => {
          this._addField(fd, field, project, `projects[${index}]`);
        });
      }

      if (type.projectSettings) {
        type.projectSettings.forEach((field) => {
          this._addField(fd, field, project, `projects[${index}]`);
        });
      }

      if (project.sourceFile) {
        project.sourceFile.forEach((file, i) => {
          fd.append(`projects[${index}][files][${i}]`, file.file, file.file.name);
        });
      }
    });

    axiosApiInstance.post('projects', fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_CREATE', 'success');

        this.props.history.push(urlPageProjects());
      })
      .catch((error) => {
        if (error.response) {
          const res = error.response;

          let message = '';

          if (res.data.hasOwnProperty('message')) {
            message = res.data.message;
          } else {
            const entries = Object.entries(res.data);

            entries.forEach((item) => {
              item[0] = item[0].split('.').slice(0, 2).join('.');
              return item;
            });
            const temp = groupBy(entries, (item) => {
              return item[0];
            });

            const err = {};

            // eslint-disable-next-line fp/no-loops,guard-for-in,no-restricted-syntax
            for (const prop in temp) {
              err[prop] = flatten(temp[prop].map((item) => item[1]));
            }

            // eslint-disable-next-line fp/no-loops,guard-for-in,no-restricted-syntax
            for (const prop in err) {
              err[prop] = err[prop].map((item) => item.replace(`${prop}.`, ''));
            }
            message = JSON.stringify(err);
          }

          this.props.notification('NOTIFICATION.ERROR_PROJECT_CREATE', 'error', `Status: ${res.status} (${res.statusText}). Message: ${message}`);
          this.setState({
            LOAD: false,
          });
        }
      });
  };

  _checkProjectTitle = () => {
    const fd = new FormData();

    this.state.projects.slice(0, Number(this.state.countProjects)).forEach((project, index) => {
      project = {
        ...this.state.project,
        ...project,
      };
      if (project.title) {
        fd.append(`projects[${index}][title]`, project.title.trim());
      }
    });

    if ([ ...fd ].length > 0) {
      this.setState({
        LOAD: true,
      });
      axiosApiInstance.post('projects/check', fd, {})
        .then((response) => {
          if (response.data.data.invalid.length > 0) {
            this.setState({
              invalidProjectTitles: response.data.data.invalid,
              showInvalid: true,
              LOAD: false,
            });
          } else {
            this.setState({
              curStep: this.state.curStep + 1,
              showInvalid: false,
              LOAD: false,
            });
          }
        })
        .catch((error) => {
          const message = JSON.stringify(error);

          this.props.notification('NOTIFICATION.ERROR_PROJECT_CREATE', 'error', `Message: ${message}`);
          this.setState({
            LOAD: false,
          });
        });
    }
  };

  handleStepChange = (event) => {
    window.scrollTo(0, 0);
    const { curStep } = this.state;

    if (event.target.name === 'prev_step') {
      if (curStep > 0) {
        this.setState({
          curStep: curStep - 1,
          showInvalid: false,
        });
      }
    } else if (event.target.name === 'next_step') {
      if (curStep <= this._steps.length - 1) {
        if (this._steps[curStep].check ? this._steps[curStep].check() : true) {
          if (this._steps[curStep + 1].preRender) {
            this._steps[curStep + 1].preRender();
          }
          if (curStep === 1) {
            this._checkProjectTitle();
          } else {
            this.setState({
              curStep: curStep + 1,
              showInvalid: false,
            });
          }
        } else {
          this.setState({
            showInvalid: true,
          });
        }
      }
    }
  };

  createProject = () => {
    if (this.state.project.source === 'shutterstock') {
      this.setState({
        modalCreateShutterstock: true,
      });
    } else {
      this._postProject();
    }
  };

  render () {
    const { curStep } = this.state;
    const { lang } = this.props;

    return this.state.LOAD ? (
      <Preload />
    ) : (
      <>
        <SubHeader
          title={lang['PROJECT.ADDED_PROJECT']}
          info={lang[this._steps[curStep].title]}
          toolbar={
            <Button
              variant='default'
              className="btn-bold"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              {lang['GLOBAL.BACK']}
            </Button>
          }
        />
        <div className='kt-portlet'>
          <div className='kt-portlet__body kt-portlet__body--fit'>
            <div className="kt-grid kt-wizard-v1 kt-wizard-v1--white">
              <div className='kt-grid__item  p-3 p-sm-5'>
                <div className='kt-wizard-v1__nav'>
                  <div className='kt-wizard-v1__nav-items'>
                    {this._steps.map((item, index) => {
                      return (
                        <div
                          key={item.title}
                          className="kt-wizard-v1__nav-item"
                          data-ktwizard-state={curStep >= index ? 'current' : 'pending'}
                        >
                          <div className="kt-wizard-v1__nav-body">
                            <div className="kt-wizard-v1__nav-icon">
                              {item.icon}
                            </div>
                            <div className="kt-wizard-v1__nav-label">
                              {lang[item.title]}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper pl-3 pr-3 pb-3 pl-sm-5 pr-sm-5 pb-sm-5 pt-0'>
                <form className='kt-form w-100 pt-0 pb-0'>
                  {this._steps[curStep].body && this._steps[curStep].body()}
                  <FormActions
                    step={curStep}
                    lastStep={this._steps.length - 1}
                    onStepChange={this.handleStepChange}
                    onSubmit={this.createProject}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.modalCreateShutterstock}
          close={() => {
            this.setState({
              modalCreateShutterstock: false,
            });
          }}
          title={lang['PROJECT.CREATE_SHUTTERSTOCK']}
          footer={
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    modalCreateShutterstock: false,
                  });
                }}
              >{lang['GLOBAL.CANCEL']}</Button>
              <Button
                variant="success"
                onClick={async () => {
                  await this._postProject();
                }}
              >
                {lang['GLOBAL.CREATE']}
              </Button>
            </>}
        />
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    config: store.config,
    cRoles: store.user.roles,
    categories: store.categories,
    users: store.users,
    tags: store.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

ProjectCreate.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(ProjectCreate);
