import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import React from 'react';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';
import { status } from '../../../utils/statusToColor';
import { formatStringToDate } from '../../../utils/formats';
import roles from '../../customers/roles/roles';
import urlPageProjectEdit from '../../../urls/urlPageProjectEdit';
import urlPageProjectsEdit from '../../../urls/urlPageProjectsEdit';
import { projectStatuses, coloringProjectTypes } from '../projectTypes';
import taskTypes from '../../tasks/taskTypes';

/**
 * Builds the subheader for the project information page
 *
 * @this ProjectInfo
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function () {
  const { project } = this.state;
  const { lang, userRoles } = this.props;
  const { hasBurned } = this.computed;

  const canDelete = (project, options = {
    projectTypes: [],
    taskType: '',
  }) => {
    return options.projectTypes.includes(project.type)
      && !project.tasks.find((task) => task.type === options.taskType)?.started_at;
  };

  const deleteProjectBtn = () => {
    const canDeleteArtistProject = canDelete(project, {
      projectTypes: [ coloringProjectTypes.COLORING_ARTIST, coloringProjectTypes.COLORING ],
      taskType: taskTypes.artist_drawing.type,
    });
    const canDeleteDesignerProject = canDelete(project, {
      projectTypes: [ coloringProjectTypes.COLORING_ANY ],
      taskType: taskTypes.designer_coloring.type,
    });

    const canDeleteProject = canDeleteArtistProject || canDeleteDesignerProject;

    return canDeleteProject && (
      <Button
        variant="danger"
        onClick={() => {
          this.setState({
            modalRemove: true,
          });
        }}
      >
        {lang['PROJECT.CLOSE']}
      </Button>
    );
  };

  const canArchive = (project, options = {
    taskTypes: [],
    taskStatuses: [],
  }) => {
    return checkRole([ ...groupsRoles.admin, roles['content-manager'].key ], userRoles)
      && project.tasks.find(
        (task) => options.taskTypes.includes(task.type) && options.taskStatuses.includes(task.status)
      )
      && project.type !== coloringProjectTypes.COLORING_ANY;
  };

  const archiveProjectBtn = () => {
    const canArchiveProject = canArchive(project, {
      taskTypes: [
        status.process__manager_checking,
        status.process__designers_group_choosing,
        status.process__designer_choosing,
      ],
      taskStatuses: [ status.waiting, status.process ],
    });

    return canArchiveProject && (
      <Button
        variant="label-warning"
        className="btn-bold"
        onClick={() => {
          this.setState({
            modalArchive: true,
          });
        }}
      >
        {lang['PROJECT.ARCHIVE_PROJECT']}
      </Button>
    );
  };

  const unArchiveProjectBtn = () => {
    const canUnArchiveProject = project.status === status.archived &&
      checkRole([ ...groupsRoles.admin, roles['content-manager'].key ], userRoles);

    return canUnArchiveProject && (
      <Button
        variant="warning"
        onClick={() => {
          this.setState({
            modalUnArchive: true,
          });
        }}
      >
        {lang['PROJECT.UNARCHIVE_PROJECT']}
      </Button>
    );
  };

  return (
    <SubHeader
      title={project.title ? project.title : `Project #${project.id}`}
      info={lang[`PROJECT.TYPE.${project.type}`]}
      toolbar={(
        <>
          {checkRole([ ...groupsRoles.admin, ...groupsRoles.managers.all ], userRoles) && (
            <>
              {project.status === status.draft && !hasBurned && (
                <Button
                  variant="success"
                  onClick={() => {
                    this._startProject();
                  }}
                >
                  {lang['GLOBAL.START']}
                </Button>
              )}
              {hasBurned && (
                <Button
                  variant="warning"
                  onClick={() => {
                    this.setState({
                      modalRestart: true,
                      restart: formatStringToDate(project.deadline_to)?.getTime() < Date.now()
                        ? {
                          deadline_to: '',
                          tasks: [ ...project.tasks ],
                        }
                        : {
                          deadline_to: project.deadline_to,
                          tasks: [ project.tasks.find((task) => task.has_burned) ],
                        },
                    });
                  }}
                >
                  {lang['PROJECT.RESTART']}
                </Button>
              )}
              {deleteProjectBtn()}
            </>
          )}
          {project.status !== status.finished && checkRole([ ...groupsRoles.admin, ...groupsRoles.managers.all, roles['lead-coloring-editor'].key ], userRoles) && (
            <>
              <Button
                as={Link}
                to={project.status === projectStatuses.reopened ? urlPageProjectsEdit({ id: project.id }) : urlPageProjectEdit({ projectId: project.id })}
                variant="secondary"
              >
                {lang['GLOBAL.EDIT']}
              </Button>
            </>
          )}
          {archiveProjectBtn()}
          {unArchiveProjectBtn()}
        </>
      )}
    />
  );
}
